<template>
  <v-container class="px-0">
    <v-row class="mx-0">
      <v-data-iterator
        :items="loading ? [] : items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        hide-default-footer
        hide-default-header
        :must-sort="!sortDisabled"
        :sort-by="sortDisabled ? '' : sortBy.value"
        :sort-desc="sortDisabled ? undefined : sortDesc"
        :search="searchTerm"
        :loading="loading"
      >
        <template v-slot:no-data>
          <data-iterator-no-results-container
            v-if="searchTerm || filterLabels"
            @clearAllFilters="$emit('clearAllFilters')"
            :searchTerm="searchTerm"
            :filterLabels="filterLabels"
          />

          <slot name="no-data" v-else></slot>
        </template>

        <template v-slot:loading>
          <slot name="loading"></slot>
        </template>

        <template v-slot:no-results>
          <data-iterator-no-results-container
            @clearAllFilters="$emit('clearAllFilters')"
            :searchTerm="searchTerm"
          />
        </template>

        <template v-slot:default="props">
          <v-container class="px-0">
            <v-row class="responsive-row">
              <template v-if="listStyle === 0">
                <slot
                  name="grid"
                  v-bind:items="props.items"
                  v-bind:filteredKeys="props.filteredKeys"
                >
                </slot>
              </template>
              <template v-if="listStyle === 1">
                <slot
                  name="list"
                  v-bind:items="props.items"
                  v-bind:filteredKeys="props.filteredKeys"
                >
                </slot>
              </template>
            </v-row>
          </v-container>
        </template>

        <template v-if="showFooter" v-slot:footer>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            :listStyle="listStyle"
            v-on="$listeners"
          ></data-iterator-footer>
        </template>
      </v-data-iterator>
    </v-row>
  </v-container>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import DataIteratorFooter from "./DataIteratorFooter.vue";
import DataIteratorNoResultsContainer from "./DataIteratorNoResultsContainer.vue";

export default {
  mixins: [DataIteratorMixin],
  components: {
    DataIteratorFooter,
    DataIteratorNoResultsContainer,
  },
  props: {
    sortBy: Object,
    sortDesc: Boolean,
    sortDisabled: Boolean,
    filterLabels: Array,
    itemsPerPage: Number,
    loading: Boolean,
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-iterator {
  width: 100%;
}

.v-data-iterator::v-deep {
  .row {
    &--grid {
      margin: -16px;
      .col {
        padding: 16px !important;
      }
    }
    &--list {
      margin: -20px;
      .col {
        padding: 20px !important;
      }
    }
  }
}
</style>
