const ConfirmationBoxMixin = {
    data: function () {
        return {
            open: false,
            type: 'copy', // 'copy' || 'remove' || 'update' || 'cache'
            application: false
        }
    },
    props: {
        id: {
            type: String,
        },
        top: {
            type: Boolean,
        },
        instance: {
            typeof: [Object, Array],
            default: () => { },
        },
        nudge: {
            type: String,
            default: '8px'
        },
        applicationUpdate: {
            type: Object,
            default: () => ({
                woocommerce: true,
                wordpress: true,
            }),
        },
    },
    methods: {
        submitCard: function () {
            this.$store.dispatch("addAlert", { type: this.type, application: this.application, items: this.instance, success: true });

            this.closeCard()
        },
        closeCard: function () {
            this.open = false
        },
    },
    computed: {
        attachNode: function () {
            return this.id ? `#${this.id}` : ''
        },
        hasMultipleInstances: function () {
            return this.instance.title == true || this.instance.length <= 1;
        },
        applicationToUpdate: function () {
            if (
                this.applicationUpdate.wordpress &&
                !this.applicationUpdate.woocommerce
            ) {
                return "WordPress";
            } else if (
                !this.applicationUpdate.wordpress &&
                this.applicationUpdate.woocommerce
            ) {
                return "WooCommerce";
            } else if (
                this.applicationUpdate.wordpress &&
                this.applicationUpdate.woocommerce
            ) {
                return "both";
            } else {
                return false;
            }
        },
    },
}

export default ConfirmationBoxMixin