import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import breakpointsSettings from './breakpointsSettings';
import iconsSettings from './iconsSettings';
import themeSettings from './themeSettings';

const options = {
  breakpoint: breakpointsSettings,
  theme: themeSettings,
  icons: iconsSettings
}

export { options }

export default new Vuetify({
  breakpoint: breakpointsSettings,
  theme: themeSettings,
  icons: iconsSettings
});

Vue.use(Vuetify);