<template lang="">
  <div class="loader-bg">
    <v-progress-circular size="32" indeterminate color="gray lighten-2" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loader-bg {
  background: var(--v-gray-lighten5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
