<template>
  <svg
    width="81"
    height="70"
    viewBox="0 0 81 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.12635 46.7108C-6.37991 36.8045 9.93073 5.38297 33.0834 1.39448C59.0572 -3.08041 77.2811 16.3756 72.7737 38.8311C67.7962 63.589 35.1263 75.976 25.8198 67.1721C20.3397 62 29.4841 55.6768 22.7393 49.3212C16.0107 42.9655 9.02278 51.3316 4.12635 46.7108Z"
      fill="#F9F9FB"
    />
    <path
      d="M15 12L63 12C63.9901 12 68 14.0264 68 15V23H12L12 15C12 13.8944 13.8944 12 15 12Z"
      fill="#EEEEF4"
    />
    <path
      d="M65.8131 59.3063L12.8767 59.3063C11.8866 59.3063 11.0946 58.4977 11.0946 57.5242V39.4881L11.0946 21.4521L67.8263 21.4521V57.2931C67.8263 58.3987 66.9187 59.3063 65.8131 59.3063Z"
      fill="white"
    />
    <path
      d="M64.0045 12H15.9955C13.7888 12 12 13.8285 12 16.0841V55.9159C12 58.1715 13.7888 60 15.9955 60H64.0045C66.2112 60 68 58.1715 68 55.9159V16.0841C68 13.8285 66.2112 12 64.0045 12Z"
      stroke="#797997"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 22L66 22"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M17 18.5C17.8284 18.5 18.5 17.8284 18.5 17C18.5 16.1716 17.8284 15.5 17 15.5C16.1716 15.5 15.5 16.1716 15.5 17C15.5 17.8284 16.1716 18.5 17 18.5Z"
      stroke="#797997"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M23 18.5C23.8284 18.5 24.5 17.8284 24.5 17C24.5 16.1716 23.8284 15.5 23 15.5C22.1716 15.5 21.5 16.1716 21.5 17C21.5 17.8284 22.1716 18.5 23 18.5Z"
      stroke="#797997"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M29 18.5C29.8284 18.5 30.5 17.8284 30.5 17C30.5 16.1716 29.8284 15.5 29 15.5C28.1716 15.5 27.5 16.1716 27.5 17C27.5 17.8284 28.1716 18.5 29 18.5Z"
      stroke="#797997"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M63 15H37C36.2158 15 35 15.8954 35 17C35 18.1046 36.2158 19 37 19H63C63.7842 19 65 18.1046 65 17C65 15.8954 63.7842 15 63 15Z"
      fill="#CCCCE2"
    />
    <path
      d="M32.3036 38C32.3036 41.0585 34.0835 43.6908 36.6657 44.9443L32.9805 34.8663C32.5543 35.8189 32.3036 36.8969 32.3036 38Z"
      fill="#797997"
    />
    <path
      d="M45.2145 37.5989C45.2145 36.6462 44.8635 35.9944 44.5877 35.4679C44.1866 34.8412 43.8356 34.2897 43.8356 33.6629C43.8356 32.961 44.3621 32.2841 45.1393 32.2841C45.1643 32.2841 45.2145 32.2841 45.2395 32.2841C43.8607 31.0306 42.0306 30.2534 40.0251 30.2534C37.3175 30.2534 34.961 31.6323 33.5822 33.7381C33.7577 33.7381 33.9331 33.7381 34.0836 33.7381C34.8858 33.7381 36.1393 33.6379 36.1393 33.6379C36.5655 33.6379 36.6156 34.2145 36.1894 34.2646C36.1894 34.2646 35.7632 34.3147 35.312 34.3398L38.1198 42.7131L39.7994 37.649L38.5961 34.3649C38.1699 34.3649 37.7939 34.2897 37.7939 34.2897C37.3677 34.2897 37.4178 33.6379 37.844 33.6629C37.844 33.6629 39.1226 33.7632 39.8746 33.7632C40.6769 33.7632 41.9304 33.6629 41.9304 33.6629C42.3565 33.6629 42.4067 34.2395 41.9805 34.2897C41.9805 34.2897 41.5543 34.3398 41.1031 34.3649L43.8858 42.6629L44.6629 40.0807C44.9888 39.0028 45.2395 38.2507 45.2395 37.5738L45.2145 37.5989Z"
      fill="#797997"
    />
    <path
      d="M40.1504 38.6769L37.844 45.3956C38.5459 45.5961 39.273 45.7215 40.0251 45.7215C40.9276 45.7215 41.7799 45.571 42.5822 45.2953C42.5822 45.2702 42.532 45.2201 42.532 45.195L40.1504 38.702V38.6769Z"
      fill="#797997"
    />
    <path
      d="M46.7939 34.2897C46.8189 34.5404 46.844 34.7911 46.844 35.0919C46.844 35.8691 46.6936 36.7465 46.2674 37.8496L43.9109 44.6685C46.2173 43.3398 47.7465 40.8329 47.7465 38C47.7465 36.6462 47.3955 35.3928 46.7939 34.2897Z"
      fill="#797997"
    />
    <path
      d="M40 29C35.0362 29 31 33.0362 31 38C31 42.9638 35.0362 47 40 47C44.9638 47 49 42.9638 49 38C49 33.0362 44.9638 29 40 29ZM40 46.5989C35.2618 46.5989 31.4011 42.7382 31.4011 38C31.4011 33.2618 35.2618 29.4011 40 29.4011C44.7382 29.4011 48.5989 33.2618 48.5989 38C48.5989 42.7382 44.7382 46.5989 40 46.5989Z"
      fill="#797997"
    />
    <path
      d="M76 44L76 64"
      stroke="#E71D1D"
      stroke-width="2.12232"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M54 44L54 64"
      stroke="#E71D1D"
      stroke-width="2.12232"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <rect x="50" y="45" width="31" height="8" rx="0.825069" fill="white" />
    <mask
      id="mask0_6347_167400"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="49"
      y="45"
      width="32"
      height="8"
    >
      <path
        d="M80.1877 52.4694L49.8123 52.4694C49.7184 52.4694 49.5306 52.3595 49.5306 52.0769V45.9231C49.5306 45.6405 49.7184 45.5306 49.8123 45.5306L80.1877 45.5306C80.2816 45.5306 80.4694 45.6405 80.4694 45.9231V52.0769C80.4694 52.3595 80.2816 52.4694 80.1877 52.4694Z"
        fill="#EEEEF4"
        stroke="#FF004F"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </mask>
    <g mask="url(#mask0_6347_167400)">
      <path
        d="M85.5218 39.8846L76.3802 62.653L75.3613 60.1154L84.5029 37.3471L85.5218 39.8846Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.176 39.8848L70.0508 62.6519L69.0323 60.1152L78.1575 37.3481L79.176 39.8848Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.847 39.8846L63.7054 62.653L62.6865 60.1154L71.8281 37.3471L72.847 39.8846Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.5013 39.8848L57.3761 62.6519L56.3575 60.1152L65.4827 37.3481L66.5013 39.8848Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.1723 39.8846L51.0306 62.653L50.0117 60.1154L59.1534 37.3471L60.1723 39.8846Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.8269 39.8838L44.7013 62.6519L43.6828 60.1154L52.8218 37.3536L53.8269 39.8838Z"
        fill="#E71D1D"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.1539 52.4694L49.8461 52.4694C49.7336 52.4694 49.5306 52.3423 49.5306 52.0385V45.9615C49.5306 45.6577 49.7336 45.5306 49.8461 45.5306L80.1539 45.5306C80.2664 45.5306 80.4694 45.6577 80.4694 45.9615V52.0385C80.4694 52.3423 80.2664 52.4694 80.1539 52.4694Z"
        stroke="#E71D1D"
        stroke-width="1.06116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>