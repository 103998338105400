const SnackBarMixin = {
    props: {
        checkedItems: {
            type: Array,
        },
        items: {
            type: Array,
        }
    },
    computed: {
        numberOfCheckedItems: function () {
            return this.checkedItems.length;
        },
        isAllSelected: function () {
            return this.checkedItems.length === this.items.length;
        }
    },
    methods: {
        selectAll: function (data) {
            this.$emit("update:check-all", data);
        },
    },
}

export default SnackBarMixin