const DataIteratorMixin = {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => ([])
        },
        listStyle: {
            type: Number,
            default: 0
        },
        searchTerm: {
            type: String,
            default: ''
        },
        possibleItemsPerPage: {
            type: Array,
            default: () => [
                [4, 8, 16]
            ],
        },
        keys: {
            type: [Array, Boolean],
            default: () => {
                false
            }
        },
        sortBy: {
            type: [String, Object, Boolean],
            default: false
        },
        sortDesc: {
            type: Boolean,
            default: true
        },
        page: Number,
        itemsPerPage: Number,
        itemsTotal: Number,
    },
    watch: {
        listStyle: function (newValue) {
            this.updateItemsPerPage(this.possibleItemsPerPage[newValue][0])
        }
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.itemsTotal / this.itemsPerPage);
        },
        filteredKeys() {
            return this.sortBy ? this.keys : false
        },
    },
}

export default DataIteratorMixin