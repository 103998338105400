const LabelsModule = {
    state: {
        availableLabels: [],
    },
    mutations: {
        setUserLabels: function (state, payload) {
            state.availableLabels = payload
        },
    },
    actions: {
        updateLabelInUse: function (context, { label, newState }) {
            const newLabels = context.state.availableLabels.map(item => {
                if (item.title == label.title && item.color == label.color) {
                    item.in_use = newState
                }
                return item
            })

            context.commit('setUserLabels', newLabels)
        },
        setUserLabels: function (context, payload) {
            context.commit('setUserLabels', payload)
        },
    }
}

export default LabelsModule