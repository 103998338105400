<template>
  <v-col cols="12" lg="2" class="sticky-sidebar pt-8">
    <!-- <template v-if="!loadingTags"> -->
    <v-list class="pt-0 sticky-sidebar__item">
      <v-list-item-group
        color="primary"
        class="d-flex flex-row flex-lg-column justify-start"
      >
        <v-list-item
          value=""
          :active-class="'elevation-4--before'"
          :ripple="false"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader type="heading" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in 10" :key="item" :ripple="false">
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader type="text" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-col>
</template>

<script>
export default {};
</script>

<style>
</style>