<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1328 13.7137C15.6092 13.7137 18.4274 11.1017 18.4274 7.87969C18.4274 4.65764 15.6092 2.04565 12.1328 2.04565C8.65633 2.04565 5.83813 4.65764 5.83813 7.87969C5.83813 11.1017 8.65633 13.7137 12.1328 13.7137Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1328 2.57885C8.91098 2.57885 6.36814 4.98877 6.36814 7.87958C6.36814 10.7704 8.91098 13.1803 12.1328 13.1803C15.3546 13.1803 17.8974 10.7704 17.8974 7.87958C17.8974 4.98877 15.3546 2.57885 12.1328 2.57885ZM5.37756 7.87958C5.37756 4.36646 8.44004 1.58496 12.1328 1.58496C15.8255 1.58496 18.888 4.36646 18.888 7.87958C18.888 11.3927 15.8255 14.1742 12.1328 14.1742C8.44004 14.1742 5.37756 11.3927 5.37756 7.87958Z"
      fill="white"
    />
    <path
      d="M16.9056 6.49792H7.35991C6.51946 6.49792 5.83813 7.17094 5.83813 8.00114V17.4304C5.83813 18.2606 6.51946 18.9336 7.35991 18.9336H16.9056C17.7461 18.9336 18.4274 18.2606 18.4274 17.4304V8.00114C18.4274 7.17094 17.7461 6.49792 16.9056 6.49792Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.38624 6.87437C6.82396 6.87437 6.36814 7.33019 6.36814 7.89246V17.3855C6.36814 17.9478 6.82396 18.4036 7.38624 18.4036H16.8793C17.4416 18.4036 17.8974 17.9478 17.8974 17.3855V7.89246C17.8974 7.33019 17.4416 6.87437 16.8793 6.87437H7.38624ZM5.37756 7.89246C5.37756 6.7831 6.27688 5.88379 7.38624 5.88379H16.8793C17.9887 5.88379 18.888 6.7831 18.888 7.89246V17.3855C18.888 18.4949 17.9887 19.3942 16.8793 19.3942H7.38624C6.27688 19.3942 5.37756 18.4949 5.37756 17.3855V7.89246Z"
      fill="white"
    />
    <path
      d="M7.8803 1.16333C7.76038 0.946975 7.83855 0.674361 8.0549 0.554431C8.27126 0.434502 8.54388 0.512673 8.66381 0.729031L11.1026 5.12869C11.2225 5.34505 11.1443 5.61767 10.928 5.7376C10.7116 5.85753 10.439 5.77936 10.3191 5.563L7.8803 1.16333Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.17515 0.771359C8.27168 0.717852 8.39331 0.752728 8.44681 0.849257L10.8856 5.24892C10.9391 5.34545 10.9042 5.46708 10.8077 5.52059C10.7112 5.57409 10.5895 5.53922 10.536 5.44269L8.09725 1.04302C8.04375 0.946494 8.07862 0.824866 8.17515 0.771359ZM8.88075 0.608721C8.6944 0.272534 8.2708 0.151068 7.93461 0.337419C7.59843 0.523771 7.47696 0.947372 7.66331 1.28356L10.1021 5.68322C10.2884 6.01941 10.712 6.14088 11.0482 5.95452C11.3844 5.76817 11.5059 5.34457 11.3195 5.00838L8.88075 0.608721Z"
      fill="white"
    />
    <path
      d="M18.7344 9.54189C18.7344 8.62385 19.4562 7.87964 20.3465 7.87964C21.2368 7.87964 21.9585 8.62385 21.9585 9.54189V15.429C21.9585 16.3471 21.2368 17.0913 20.3465 17.0913C19.4562 17.0913 18.7344 16.3471 18.7344 15.429V9.54189Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.3465 8.06935C21.1299 8.06935 21.765 8.69945 21.765 9.47672V15.3408C21.765 16.118 21.1299 16.7481 20.3465 16.7481C19.5631 16.7481 18.9281 16.118 18.9281 15.3408V9.47672C18.9281 8.69945 19.5631 8.06935 20.3465 8.06935ZM22.2656 9.47672C22.2656 8.42512 21.4064 7.57263 20.3465 7.57263C19.2866 7.57263 18.4274 8.42512 18.4274 9.47672V15.3408C18.4274 16.3924 19.2866 17.2449 20.3465 17.2449C21.4064 17.2449 22.2656 16.3924 22.2656 15.3408V9.47672Z"
      fill="white"
    />
    <path
      d="M12.9004 16.2694C12.9004 15.3667 13.6222 14.6349 14.5125 14.6349C15.4028 14.6349 16.1245 15.3667 16.1245 16.2694V22.0585C16.1245 22.9612 15.4028 23.693 14.5125 23.693C13.6222 23.693 12.9004 22.9612 12.9004 22.0585V16.2694Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5124 14.8245C15.2958 14.8245 15.9309 15.4546 15.9309 16.2318V22.0959C15.9309 22.8732 15.2958 23.5033 14.5124 23.5033C13.729 23.5033 13.094 22.8732 13.094 22.0959V16.2318C13.094 15.4546 13.729 14.8245 14.5124 14.8245ZM16.4315 16.2318C16.4315 15.1802 15.5723 14.3278 14.5124 14.3278C13.4525 14.3278 12.5933 15.1802 12.5933 16.2318V22.0959C12.5933 23.1475 13.4525 24 14.5124 24C15.5723 24 16.4315 23.1475 16.4315 22.0959V16.2318Z"
      fill="white"
    />
    <path
      d="M16.4467 1.16333C16.5667 0.946975 16.4885 0.674361 16.2721 0.554431C16.0558 0.434502 15.7831 0.512673 15.6632 0.729031L13.2244 5.12869C13.1045 5.34505 13.1827 5.61767 13.399 5.7376C13.6154 5.85753 13.888 5.77936 14.0079 5.563L16.4467 1.16333Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1519 0.771359C16.0553 0.717852 15.9337 0.752728 15.8802 0.849257L13.4414 5.24892C13.3879 5.34545 13.4228 5.46708 13.5193 5.52059C13.6159 5.57409 13.7375 5.53922 13.791 5.44269L16.2298 1.04302C16.2833 0.946494 16.2484 0.824866 16.1519 0.771359ZM15.4463 0.608721C15.6326 0.272534 16.0562 0.151068 16.3924 0.337419C16.7286 0.523771 16.8501 0.947372 16.6637 1.28356L14.2249 5.68322C14.0386 6.01941 13.615 6.14088 13.2788 5.95452C12.9426 5.76817 12.8211 5.34457 13.0075 5.00838L15.4463 0.608721Z"
      fill="white"
    />
    <path
      d="M5.68461 9.54189C5.68461 8.62385 4.92851 7.87964 3.99581 7.87964C3.06311 7.87964 2.30701 8.62385 2.30701 9.54189V15.429C2.30701 16.3471 3.06311 17.0913 3.99581 17.0913C4.92851 17.0913 5.68461 16.3471 5.68461 15.429V9.54189Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.91909 8.06935C3.1357 8.06935 2.50063 8.69945 2.50063 9.47672V15.3408C2.50063 16.118 3.1357 16.7481 3.91909 16.7481C4.70248 16.7481 5.33755 16.118 5.33755 15.3408V9.47672C5.33755 8.69945 4.70248 8.06935 3.91909 8.06935ZM2 9.47672C2 8.42512 2.85921 7.57263 3.91909 7.57263C4.97898 7.57263 5.83818 8.42512 5.83818 9.47672V15.3408C5.83818 16.3924 4.97898 17.2449 3.91909 17.2449C2.85921 17.2449 2 16.3924 2 15.3408V9.47672Z"
      fill="white"
    />
    <path
      d="M11.5187 16.2694C11.5187 15.3667 10.7626 14.6349 9.82991 14.6349C8.89722 14.6349 8.14111 15.3667 8.14111 16.2694V22.0585C8.14111 22.9612 8.89722 23.693 9.82991 23.693C10.7626 23.693 11.5187 22.9612 11.5187 22.0585V16.2694Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.82988 14.8245C9.07782 14.8245 8.46816 15.4546 8.46816 16.2318V22.0959C8.46816 22.8732 9.07782 23.5033 9.82988 23.5033C10.5819 23.5033 11.1916 22.8732 11.1916 22.0959V16.2318C11.1916 15.4546 10.5819 14.8245 9.82988 14.8245ZM7.98755 16.2318C7.98755 15.1802 8.81239 14.3278 9.82988 14.3278C10.8474 14.3278 11.6722 15.1802 11.6722 16.2318V22.0959C11.6722 23.1475 10.8474 24 9.82988 24C8.81239 24 7.98755 23.1475 7.98755 22.0959V16.2318Z"
      fill="white"
    />
    <path
      d="M12.1328 13.7137C15.6092 13.7137 18.4274 11.1017 18.4274 7.87969C18.4274 4.65764 15.6092 2.04565 12.1328 2.04565C8.65633 2.04565 5.83813 4.65764 5.83813 7.87969C5.83813 11.1017 8.65633 13.7137 12.1328 13.7137Z"
      fill="#A4C639"
    />
    <path
      d="M16.9056 6.49792H7.35991C6.51946 6.49792 5.83813 7.17094 5.83813 8.00114V17.4304C5.83813 18.2606 6.51946 18.9336 7.35991 18.9336H16.9056C17.7461 18.9336 18.4274 18.2606 18.4274 17.4304V8.00114C18.4274 7.17094 17.7461 6.49792 16.9056 6.49792Z"
      fill="#A4C639"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7345 8.34034H5.68469V7.87976H18.7345V8.34034Z"
      fill="white"
    />
    <path
      d="M9.29255 5.26979C9.41973 5.26979 9.52284 5.16668 9.52284 5.0395C9.52284 4.91231 9.41973 4.8092 9.29255 4.8092C9.16536 4.8092 9.06226 4.91231 9.06226 5.0395C9.06226 5.16668 9.16536 5.26979 9.29255 5.26979Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.29259 5.01126C9.27698 5.01126 9.26431 5.02392 9.26431 5.03954C9.26431 5.05516 9.27698 5.06782 9.29259 5.06782C9.30821 5.06782 9.32088 5.05516 9.32088 5.03954C9.32088 5.02392 9.30821 5.01126 9.29259 5.01126ZM8.75525 5.03954C8.75525 4.74278 8.99583 4.5022 9.29259 4.5022C9.58936 4.5022 9.82994 4.74278 9.82994 5.03954C9.82994 5.33631 9.58936 5.57689 9.29259 5.57689C8.99583 5.57689 8.75525 5.33631 8.75525 5.03954Z"
      fill="white"
    />
    <path
      d="M15.0499 5.26979C15.2194 5.26979 15.3569 5.16668 15.3569 5.0395C15.3569 4.91231 15.2194 4.8092 15.0499 4.8092C14.8803 4.8092 14.7428 4.91231 14.7428 5.0395C14.7428 5.16668 14.8803 5.26979 15.0499 5.26979Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0498 5.01126C15.0364 5.01126 15.0256 5.02392 15.0256 5.03954C15.0256 5.05516 15.0364 5.06782 15.0498 5.06782C15.0632 5.06782 15.0741 5.05516 15.0741 5.03954C15.0741 5.02392 15.0632 5.01126 15.0498 5.01126ZM14.5892 5.03954C14.5892 4.74278 14.7954 4.5022 15.0498 4.5022C15.3042 4.5022 15.5104 4.74278 15.5104 5.03954C15.5104 5.33631 15.3042 5.57689 15.0498 5.57689C14.7954 5.57689 14.5892 5.33631 14.5892 5.03954Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>