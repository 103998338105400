<template>
  <div class="table-item item--visitors" :class="main ? 'group-start': ''">
    <template v-if="main">
      <div class="item item--main">
        <p class="item__value">
          <span class="item__value-current p-2">
            {{ item.info.visitors.current }}
          </span>
          <delta-indicator :value="item.info.visitors.delta" />
        </p>
      </div>
    </template>
    <template v-else>
      <div class="item">
        <span class="no-data">-</span>
      </div>
    </template>
  </div>
</template>

<script>
import DeltaIndicator from "../../DeltaIndicator.vue";

export default {
  components: {
    DeltaIndicator,
  },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false
    }
  },
};
</script>