<template>
  <span v-if="value" class="p-4" :class="valuePositive ? 'success--text' : 'error--text'">
    <v-icon
      size="12"
      class="mb-1 ml-2"
      :color="valuePositive ? 'success' : 'error'"
    >
      {{ valuePositive ? "$increase" : "$decrease" }}</v-icon
    >
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    valuePositive: function () {
      if (this.value.includes("-")) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>