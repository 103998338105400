<template>
  <div class="d-flex align-center">
    <select-dropdown
      v-if="keys"
      :value="sortByData"
      :items="keysToShow"
      :size="$vuetify.breakpoint.smAndDown ? 'md' : 'lg'"
      :class="$vuetify.breakpoint.smAndDown ? 'p-3' : 'p-2'"
      @update:value="sortChange"
    >
      <template v-slot:prepend-inner>
        <v-icon color="gray darken-1" size="16">$bandwidth</v-icon>
      </template>
    </select-dropdown>
  </div>
</template>

<script>
import SelectDropdown from "../SelectDropdown.vue";

export default {
  components: {
    SelectDropdown,
  },
  props: {
    sortBy: {
      type: [Object, Boolean],
      default: false,
    },
    keys: {
      type: [Array, Boolean],
      default: () => false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    keysToShow: function () {
      let keys = this.keys.filter((item) => {
        return item.sortable;
      });
      return keys;
    },
    sortByData: {
      get: function () {
        return this.sortBy;
      },
      set: function (newValue) {
        this.$emit("update:sortBy", newValue);
      },
    },
    sortDescData: {
      get: function () {
        return this.sortDesc;
      },
      set: function (newValue) {
        this.$emit("update:sortDesc", newValue);
      },
    },
  },
  methods: {
    sortChange: function (data) {
      this.sortByData = data;
    },
  },
};
</script>


<style lang="scss" scoped>
.v-select::v-deep {
  .v-list-item {
    position: relative;
  }
  .v-text-field__prefix {
    font-weight: $font-weight-light !important;
    color: map-get($gray, darken1) !important;
  }
  .v-select__selection {
    color: map-get($text, headings);
    font-weight: $font-weight-bold;
  }
}

.click-target {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
</style>