<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-text height="800px" class="px-8 pt-8">
        <div class="mb-8 d-flex align-start">
          <div class="d-flex flex-column">
            <span class="font-weight-light p-1 base--text">
              {{ $t('heading.onboarding.import.url.title') }}
            </span>
            <h4 class>{{ $t('heading.onboarding.import.url.subtitle') }}</h4>
          </div>

          <v-btn x-small icon @click="$emit('removeModal')" class="ml-auto">
            <v-icon size="24" color="gray">$close</v-icon>
          </v-btn>
        </div>

        <v-divider class="mb-5" />

        <p class="p-2 gray--text text--darken-2 mb-5 font-weight-light">
          {{ $t('heading.onboarding.import.url.info') }}
        </p>

        <div class="form-box">
          <div>
            <span
              class="gray--text text--darken-1 mb-1"
              :class="urlVerificationError ? 'error--text' : ''"
            >
              {{ $t('heading.onboarding.import.url.label') }}
            </span>
            <v-text-field
              v-model="url"
              outlined
              dense
              ref="input"
              :error="urlVerificationError ? true : false"
              class="mt-2 v-input--md"
              placeholder="https://"
              @input="handleInput"
              autofocus
            >
              <template v-slot:append>
                <Loader
                  v-if="urlVerificationLoading"
                  :size="20"
                  :color="'primary'"
                  :noMargins="true"
                />
              </template>
            </v-text-field>
          </div>
        </div>
        <v-scale-transition>
          <div class="error-box mt-5" v-if="urlVerificationError">
            <v-icon color="error" size="20" class="mr-2">$alertcircle</v-icon>
            <span class="error--text">
              <b class="error--text">
                <i18next :translation="$t('message.alert.failed')">
                  <template #message>
                    <span class="font-weight-medium">{{ urlVerificationError }}</span>
                  </template>
                </i18next>
              </b>
            </span>
          </div>
          <div class="warning-box mt-5" v-else-if="urlVerificationWarning">
            <v-icon color="warning" size="20" class="mr-2">$alertcircle</v-icon>
            <span class="warning--text">
              <b class="warning--text">
                <i18next :translation="$t('message.alert.warning')">
                  <template #message>
                    <span class="font-weight-medium">{{ urlVerificationWarning }}</span>
                  </template>
                </i18next>
              </b>
            </span>
          </div>
        </v-scale-transition>
      </v-card-text>
      <v-card-actions class="pt-8 d-flex align-center justify-space-between">
        <div>
          <StepIndicator :currentStep="2" :maxSteps="4" />
        </div>
        <div class="d-flex align-center">
          <v-btn
            large
            @click="$emit('removeModal')"
            text
            elevation="0"
            class="mr-3"
          >
            <span class="font-weight-bold gray--text text--darken-1">
              {{ $t('button.cancel') }}
            </span>
          </v-btn>
          <v-btn
            large
            @click="$emit('next', url)"
            :disabled="!url || urlVerificationLoading || urlVerificationError"
            color="primary"
            elevation="0"
          >
            <span class="font-weight-bold">
              <span v-if="urlVerificationWarning">{{ $t('button.continueAnyway') }}</span>
              <span v-else>{{ $t('button.continue') }}</span>
              <v-icon size="20" class="mr-0">$arrowright</v-icon>
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as _ from "lodash";
import Api from "@/apis/Api";
import Loader from "../Loader.vue";
import StepIndicator from "../StepIndicator.vue";

export default {
  components: {
    Loader,
    StepIndicator,
  },
  props: {
    isOpen: Boolean,
  },
  data: () => ({
    url: "",
    urlVerificationPassed: false,
    urlVerificationLoading: false,
    urlVerificationError: false,
    urlVerificationWarning: false,
  }),
  computed: {
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
  },
  methods: {
    handleInput: function () {
      this.urlVerificationLoading = true;
      this.debouncedVerification();
    },
    debouncedVerification: _.debounce(function () {
      this.verifyUrl();
    }, 500),
    verifyUrl() {
      this.urlVerificationLoading = true;
      this.urlVerificationPassed = false;
      this.urlVerificationError = false;
      this.urlVerificationWarning = false;
      if (!this.url.startsWith("https://") && !this.url.startsWith("http://")) {
        this.url = "https://" + this.url;
      }
      let params = new URLSearchParams({
        url: this.url,
      });
      Api.get(`/instances/import/test-wordpress-url?${params.toString()}`)
        .then((response) => {
          if (response.data.wordpress_detected) {
            this.urlVerificationPassed = true;
            return;
          }
          if (response.data.error_message) {
            this.urlVerificationError = response.data.error_message;
            return;
          }
          this.urlVerificationWarning =
            this.$t('notification.wordpress.notDetected')
        })
        .catch((error) => {
          this.urlVerificationError = Api.getErrorMessage(error);
        })
        .finally(() => {
          this.urlVerificationLoading = false;
        });
    },
    resetData: function () {
      this.url = "";
      this.urlVerificationPassed = false;
      this.urlVerificationLoading = false;
      this.urlVerificationError = false;
      this.urlVerificationWarning = false;
    },
  },
  watch: {
    isOpen: function (value) {
      this.resetData();

      if (value) {
        this.$nextTick(() => {
          this.$store.dispatch("lockBodyScroll");
        });
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: #fff;
  }

  .form-box {
    background-color: #fcfcff;
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px;
  }
  .error-box {
    background-color: var(--v-error-lighten5);
    border-radius: 8px;
    padding: 16px;
    display: flex;
  }

  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
