<template>
  <div class="table-item item--tags" :class="main ? 'group-start' : ''">
    <div class="item item--main" ref="container">
      <instance-labels
        :maxWidth="maxWidth"
        remainingAsButton
        chips
        containerClass="ml-0"
        :labels="item.labels"
        :instance="item"
        :filtersActive="true"
        @toggleFilter="toggleFilter"
        :maxLabelsDisplayed="99"
      />
    </div>
  </div>
</template>

<script>
import InstanceLabels from "../InstanceLabels.vue";

export default {
  components: {
    InstanceLabels,
  },
  data: function () {
    return {
      maxWidth: 0,
    };
  },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleFilter: function (item) {
      this.$emit("toggleFilter", item);
    },
    maxItemsContainer: function () {
      return this.$refs.container.offsetWidth;
    },
  },
  mounted: function () {
    this.maxWidth = this.maxItemsContainer();
  },
};
</script>