const DataIteratorItemMixin = {
    props: {
        item: Object,
        checked: Boolean,
    },
    methods: {
        handleCheckbox: function (newValue) {
            this.checkedComputed = newValue;
        },
    },
    computed: {
        checkedComputed: {
            get: function () {
                return this.checked;
            },
            set: function (newValue) {
                this.$emit('checked', newValue);
            }
        }
    }
}

export default DataIteratorItemMixin