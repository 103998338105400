<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6847_205555)">
      <path
        d="M21.6657 17.8594C21.3438 18.0266 21.012 18.1738 20.6719 18.3C19.5953 18.7007 18.4551 18.904 17.3063 18.9C12.8719 18.9 9.00942 15.8531 9.00942 11.9344C9.01519 11.4096 9.16067 10.8959 9.43091 10.4461C9.70114 9.99623 10.0864 9.62652 10.5469 9.375C6.53442 9.54375 5.50317 13.725 5.50317 16.1719C5.50317 23.1094 11.8875 23.8031 13.2657 23.8031C14.0063 23.8031 15.1219 23.5875 15.7969 23.3719L15.9188 23.3344C18.502 22.4417 20.7041 20.6958 22.1625 18.3844C22.2049 18.3141 22.2231 18.232 22.2143 18.1504C22.2055 18.0688 22.1702 17.9924 22.1139 17.9328C22.0575 17.8733 21.9831 17.8338 21.9021 17.8205C21.8212 17.8073 21.7381 17.8209 21.6657 17.8594Z"
        fill="url(#paint0_linear_6847_205555)"
      />
      <path
        opacity="0.35"
        d="M21.6657 17.8594C21.3438 18.0266 21.012 18.1738 20.6719 18.3C19.5953 18.7007 18.4551 18.904 17.3063 18.9C12.8719 18.9 9.00942 15.8531 9.00942 11.9344C9.01519 11.4096 9.16067 10.8959 9.43091 10.4461C9.70114 9.99623 10.0864 9.62652 10.5469 9.375C6.53442 9.54375 5.50317 13.725 5.50317 16.1719C5.50317 23.1094 11.8875 23.8031 13.2657 23.8031C14.0063 23.8031 15.1219 23.5875 15.7969 23.3719L15.9188 23.3344C18.502 22.4417 20.7041 20.6958 22.1625 18.3844C22.2049 18.3141 22.2231 18.232 22.2143 18.1504C22.2055 18.0688 22.1702 17.9924 22.1139 17.9328C22.0575 17.8733 21.9831 17.8338 21.9021 17.8205C21.8212 17.8073 21.7381 17.8209 21.6657 17.8594Z"
        fill="url(#paint1_radial_6847_205555)"
      />
      <path
        d="M9.90941 22.6219C9.07492 22.1036 8.35158 21.4249 7.78128 20.625C7.13004 19.7336 6.68176 18.7105 6.4679 17.6275C6.25404 16.5445 6.27978 15.4278 6.54332 14.3558C6.80686 13.2837 7.30181 12.2824 7.99343 11.4219C8.68506 10.5615 9.55661 9.86286 10.5469 9.37502C10.8469 9.2344 11.3438 8.99065 12.0094 9.00002C12.4778 9.00365 12.939 9.11555 13.357 9.32697C13.7749 9.53839 14.1383 9.84361 14.4188 10.2188C14.796 10.726 15.0028 11.3398 15.0094 11.9719C15.0094 11.9531 17.3063 4.5094 7.50941 4.5094C3.39378 4.5094 0.00940901 8.4094 0.00940901 11.8407C-0.00696112 13.6532 0.380544 15.4466 1.14378 17.0907C2.39373 19.7522 4.57741 21.8622 7.28032 23.0201C9.98322 24.178 13.0172 24.3032 15.8063 23.3719C14.8291 23.6805 13.7966 23.7733 12.7801 23.6438C11.7636 23.5143 10.7873 23.1656 9.91878 22.6219H9.90941Z"
        fill="url(#paint2_linear_6847_205555)"
      />
      <path
        opacity="0.41"
        d="M9.90941 22.6219C9.07492 22.1036 8.35158 21.4249 7.78128 20.625C7.13004 19.7336 6.68176 18.7105 6.4679 17.6275C6.25404 16.5445 6.27978 15.4278 6.54332 14.3558C6.80686 13.2837 7.30181 12.2824 7.99343 11.4219C8.68506 10.5615 9.55661 9.86286 10.5469 9.37502C10.8469 9.2344 11.3438 8.99065 12.0094 9.00002C12.4778 9.00365 12.939 9.11555 13.357 9.32697C13.7749 9.53839 14.1383 9.84361 14.4188 10.2188C14.796 10.726 15.0028 11.3398 15.0094 11.9719C15.0094 11.9531 17.3063 4.5094 7.50941 4.5094C3.39378 4.5094 0.00940901 8.4094 0.00940901 11.8407C-0.00696112 13.6532 0.380544 15.4466 1.14378 17.0907C2.39373 19.7522 4.57741 21.8622 7.28032 23.0201C9.98322 24.178 13.0172 24.3032 15.8063 23.3719C14.8291 23.6805 13.7966 23.7733 12.7801 23.6438C11.7636 23.5143 10.7873 23.1656 9.91878 22.6219H9.90941Z"
        fill="url(#paint3_radial_6847_205555)"
      />
      <path
        d="M14.2875 13.95C14.2031 14.0438 13.9688 14.1844 13.9688 14.475C13.9688 14.7187 14.1281 14.9625 14.4187 15.1594C15.7594 16.0969 18.3 15.9656 18.3094 15.9656C19.3088 15.965 20.2898 15.6963 21.15 15.1875C22.0161 14.6809 22.7347 13.9568 23.2346 13.0869C23.7345 12.2169 23.9984 11.2315 24 10.2281C24.0281 8.12813 23.25 6.73125 22.9406 6.1125C20.9438 2.23125 16.6594 5.87477e-08 12 5.87477e-08C8.84643 -0.000311772 5.81948 1.24078 3.57383 3.45485C1.32818 5.66892 0.0443471 8.67799 0 11.8312C0.046875 8.40937 3.45 5.64375 7.5 5.64375C7.82812 5.64375 9.70312 5.67187 11.4375 6.58125C12.656 7.18674 13.665 8.14353 14.3344 9.32812C14.9062 10.3219 15.0094 11.5875 15.0094 12.0938C15.0094 12.6 14.7562 13.3406 14.2781 13.9594L14.2875 13.95Z"
        fill="url(#paint4_radial_6847_205555)"
      />
      <path
        d="M14.2875 13.95C14.2031 14.0438 13.9688 14.1844 13.9688 14.475C13.9688 14.7187 14.1281 14.9625 14.4187 15.1594C15.7594 16.0969 18.3 15.9656 18.3094 15.9656C19.3088 15.965 20.2898 15.6963 21.15 15.1875C22.0161 14.6809 22.7347 13.9568 23.2346 13.0869C23.7345 12.2169 23.9984 11.2315 24 10.2281C24.0281 8.12813 23.25 6.73125 22.9406 6.1125C20.9438 2.23125 16.6594 5.87477e-08 12 5.87477e-08C8.84643 -0.000311772 5.81948 1.24078 3.57383 3.45485C1.32818 5.66892 0.0443471 8.67799 0 11.8312C0.046875 8.40937 3.45 5.64375 7.5 5.64375C7.82812 5.64375 9.70312 5.67187 11.4375 6.58125C12.656 7.18674 13.665 8.14353 14.3344 9.32812C14.9062 10.3219 15.0094 11.5875 15.0094 12.0938C15.0094 12.6 14.7562 13.3406 14.2781 13.9594L14.2875 13.95Z"
        fill="url(#paint5_radial_6847_205555)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6847_205555"
        x1="5.50317"
        y1="16.5937"
        x2="22.2282"
        y2="16.5937"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0C59A4" />
        <stop offset="1" stop-color="#114A8B" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_6847_205555"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.7375 16.7198) scale(8.94375 8.49656)"
      >
        <stop offset="0.7" stop-opacity="0" />
        <stop offset="0.9" stop-opacity="0.5" />
        <stop offset="1" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_6847_205555"
        x1="14.3157"
        y1="9.33752"
        x2="3.88128"
        y2="20.7094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B9DE2" />
        <stop offset="0.2" stop-color="#1595DF" />
        <stop offset="0.7" stop-color="#0680D7" />
        <stop offset="1" stop-color="#0078D4" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_6847_205555"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.61457 18.6504) rotate(-81.3844) scale(13.4424 10.8601)"
      >
        <stop offset="0.8" stop-opacity="0" />
        <stop offset="0.9" stop-opacity="0.5" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_6847_205555"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.42719 4.43587) rotate(92.2906) scale(18.9902 40.4451)"
      >
        <stop stop-color="#35C1F1" />
        <stop offset="0.1" stop-color="#34C1ED" />
        <stop offset="0.2" stop-color="#2FC2DF" />
        <stop offset="0.3" stop-color="#2BC3D2" />
        <stop offset="0.7" stop-color="#36C752" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_6847_205555"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5056 7.24687) rotate(73.7398) scale(9.12187 7.41791)"
      >
        <stop stop-color="#66EB6E" />
        <stop offset="1" stop-color="#66EB6E" stop-opacity="0" />
      </radialGradient>
      <clipPath id="clip0_6847_205555">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>