<template>
  <v-tooltip open-delay="150" bottom :disabled="!text" transition="custom-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :class="color" v-on="on" v-bind="attrs" size="16">$help</v-icon>
    </template>
    <div>
      <p class="p-3 mb-0">
        {{ text }}
      </p>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "gray--text text--darken-1",
    },
  },
};
</script>