import { render, staticRenderFns } from "./UserInfoDropdown.vue?vue&type=template&id=a43d5578&scoped=true"
import script from "./UserInfoDropdown.vue?vue&type=script&lang=js"
export * from "./UserInfoDropdown.vue?vue&type=script&lang=js"
import style0 from "./UserInfoDropdown.vue?vue&type=style&index=0&id=a43d5578&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a43d5578",
  null
  
)

export default component.exports