var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{attrs:{"small":"","color":_vm.role === 'administrator'
      ? '#fff7e5'
      : _vm.role === 'author'
      ? '#e6f9f2'
      : _vm.role === 'contributor'
      ? '#ffeff9'
      : _vm.role === 'editor'
      ? '#ffebe0'
      : _vm.role === 'subscriber'
      ? '#ebf2fd'
      : '#f2f2f7'}},[_c('span',{staticClass:"p-3",style:(_vm.role === 'administrator'
        ? 'color: #DB9C00'
        : _vm.role === 'author'
        ? 'color: #07C07E'
        : _vm.role === 'contributor'
        ? 'color: #FE33B9'
        : _vm.role === 'editor'
        ? 'color: #FF7A2F'
        : _vm.role === 'subscriber'
        ? 'color: #005EEA'
        : 'color: #797997')},[_vm._v(" "+_vm._s(_vm.role)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }