<template>
  <v-menu elevation="4" offset-overflow nudge-bottom="16px" offset-y left bottom :min-width="'220px'"
    :max-width="'290px'" :close-on-content-click="false" v-model="open" @input="handleInput" :transition="false"
    :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn class="instance-item__more-button" v-on="on" v-bind="attrs" icon :id="`instance-item-${id}`" small>
          <v-icon>$morehorizontal</v-icon>
        </v-btn>
      </slot>
    </template>
    <div v-click-outside="() => hideMainDropdown" v-if="instance.async_status.installation == 'failed'">
      <v-list class="v-list--small" v-if="!menuOpen">
        <v-list-item v-if="!inInstance" class="primary--text" @click.stop.prevent="retryInstall">
          <v-icon class="mr-4" size="20">$restore</v-icon>
          <v-list-item-title>{{ $t('button.try') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!inInstance" class="error--text" @click.stop.prevent="cancelInstall">
          <v-icon class="mr-4" size="20">$errorCircle</v-icon>
          <v-list-item-title class="text--error">{{ $t('button.installation.cancel') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <div v-click-outside="() => hideMainDropdown" v-else>
      <v-list class="v-list--small" v-if="!menuOpen">
        <v-list-item v-if="!inInstance" class="primary--text" :to="{ path: managePath }">
          <v-icon class="mr-4" size="20">$grid</v-icon>
          <v-list-item-title>{{ $t('button.manage') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!(inInstance && !$vuetify.breakpoint.mobile) && (instance.getUserPrivileges('wordpress.create_sso_link'))" class="info--text" @click="openWPLoginModal">
          <v-icon v-if="!checkingWpLogin" class="mr-4 info--text" size="20">$wordpress</v-icon>
          <loader v-else class="mr-4" size="20" :color="'info'" :noMargins="true" />
          <v-list-item-title class="info--text">{{ $t('button.wpAdmin') }}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="!inInstance" />

        <template v-if="!(inInstance && !$vuetify.breakpoint.mobile)">
          <v-list-item @click="true" color="primary" class="primary-btn"
            v-if="this.subinstance.subinstanceLabel === 'staging' && (instance.getUserPrivileges('general.push_to_live'))">
            <v-icon class="mr-4" size="20">$send</v-icon>
            <v-list-item-title>{{ $t('button.pushToLive') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-else-if="this.subinstance.subinstanceLabel !== 'staging' && (instance.getUserPrivileges('general.create_staging'))" @click="$emit('action-button-staging', subinstance)" class="primary--text">
            <v-icon class="mr-4" size="20">$copy</v-icon>
            <v-list-item-title>{{ $t('button.staging') }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item v-if="!(inInstance && !$vuetify.breakpoint.mobile) && (instance.getUserPrivileges('wordpress.update'))" @click="openUpdateModal" class="primary--text">
          <v-icon v-if="!checkingUpdates" class="mr-4" size="20">$update</v-icon>
          <loader v-else class="mr-4" size="20" :color="'primary'" :noMargins="true" />
          <v-list-item-title>{{ $t('button.update') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!(inInstance && !$vuetify.breakpoint.mobile) && (instance.getUserPrivileges('wordpress.clear_cache'))" class="primary--text"
          @click="$emit('action-button-clearcache', subinstance)">
          <v-icon class="mr-4" size="20">$restore</v-icon>
          <v-list-item-title>{{ $t('button.clearCache') }}</v-list-item-title>
        </v-list-item>
        <share-instance-modal :instance="subinstance">
          <template v-slot="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on" v-if="!(inInstance && !$vuetify.breakpoint.mobile)"
              class="primary--text">
              <v-icon class="mr-4" size="16">$shareIcon</v-icon>
              <v-list-item-title>{{ $t('button.share') }}</v-list-item-title>
            </v-list-item>
          </template>
        </share-instance-modal>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import ConfirmationBoxMixin from "../../mixins/ConfirmationBoxMixin";
import Loader from "../Loader.vue";

import Api from "../../apis/Api";
import Auth from "../../apis/Auth";

import {
  OpenUpdatesModalMixin,
  OpenWPLoginModalMixin,
} from "../../mixins/OpenModalAction";
import ShareInstanceModal from "../modal/ShareInstanceModal.vue";

export default {
  mixins: [ConfirmationBoxMixin, OpenUpdatesModalMixin, OpenWPLoginModalMixin],
  components: {
    Loader,
    ShareInstanceModal,
  },
  props: {
    main: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: Number,
    itemTitle: String,
    subinstance: Object,
    inInstance: Boolean,
  },
  data: function () {
    return {
      open: false,
      menuOpen: "",
    };
  },
  computed: {
    managePath() {
      return `/instances/${this.main ? this.instance.id : this.instance.subinstance.id}`;
    },
  },
  methods: {
    cancelInstall() {
      // TODO implement "cancelling/deleting instance in progress" ?
      this.instance.async_status.installation = "in_progress";

      Api.delete(`/instances/${this.instance.id}`, {
        data: { remove_data: true, remove_database: true },
      })
        .then(() => {
          Auth.home(true);
          this.instance.async_status.installation = "finished";
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.installation.cancelled'),
          });
          this.$emit("instance-deleted", this.instance);
        })
        .catch((error) => {
          this.instance.async_status.installation = "failed";
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    retryInstall() {
      this.$root.$emit("instance-retry-install", this.instance);
    },
    closeConfirmationCard: function () {
      this.menuOpen = "";
      this.open = false;
      this.$nextTick(() => {
        this.open = true;
      });
    },
    openConfirmationBox(menu) {
      this.menuOpen = menu;
      this.open = false;

      this.$nextTick(() => {
        this.open = true;
      });
    },
    submitCard: function (type) {
      this.$store.dispatch("addAlert", {
        type: type,
        application: this.application,
        items: this.instance,
        success: true,
      });

      this.hideMainDropdown();
    },
    openMainDropdown: function () {
      this.menuOpen = "";

      this.$nextTick(() => {
        this.open = true;
      });
    },
    hideMainDropdown: function () {
      this.open = false;
      this.$nextTick(() => {
        this.menuOpen = "";
      });
    },
    handleInput(value) {
      if (!value[0]) {
        this.menuOpen = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  margin-top: -8px;
  width: min-content !important;
}

.instance-item__more-button {
  &::before {
    color: map-get($primary, base);
  }

  &:hover,
  &[aria-expanded="true"] {
    color: map-get($primary, darken1) !important;

    &::before {
      opacity: 0.05;
    }
  }
}
.text--error{
  color: var(--v-error-base)!important;
}
.v-list.v-list--small {
  min-width: 220px;
  max-width: 220px;
}
</style>