import Api from "./Api";
import Cookie from "js-cookie";
import Echo from "laravel-echo"
import store from '../store/store'
import {changeLanguage} from "@/i18n";

export default {
  loggingViaSso: false,
  async home(skipCache = false) {
    if ((new URL(window.location)).pathname.includes('sso-login') && !this.loggingViaSso) {
      this.loggingViaSso = true;
      document.getElementById("app").innerHTML = "Redirecting... Please wait.";
      let homeResponse;
      try {
        homeResponse = await Api.get('/home');
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
      if (homeResponse.data.user) {
        try {
          await Api.post("/logout");
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      }
      try {
        await this.ssoLogin();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }

    let cacheSeconds = skipCache ? 0 : null;
    return Api.cached(cacheSeconds).get('/home').then((response) => {
      store.commit("setHome", response.data);
      if (response.data.user) {
        store.commit("setServices", response.data.user.services);
      }
      if (response.data.app.theme) {
        store.commit("changeColorsTheme", response.data.app.theme);
        if (store.state.ThemeModule.favicon) {
          let link = document.querySelector("link[rel~='icon']");
          link.href = store.state.ThemeModule.favicon;
        }
      }
      return response;
    });
  },

  async login(email, password, remember) {
    await this.getCsrfToken();
    return await Api.post("/login", { 'email': email, 'password': password, 'remember': remember }).then(response => {
      if (response.data.two_factor === true) {
        return Promise.resolve(response);
      }
      localStorage.setItem(window.location.origin + "-loggedin", true);
      store.commit("setHome", response.data);
      Api.cached().clearAll();
      this.websocketConnect();
      this.websocketListen();
			changeLanguage(store.state.home.user.language);
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  },

  async ssoLogin() {
    let params = new URLSearchParams(window.location.search);
    await this.getCsrfToken();
    return await Api.post("/sso-login?token=" + params.get("token")).then(response => {
      localStorage.setItem(window.location.origin + "-loggedin", true);
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  },

  loginWithTwoFactorAuth(data) {
    return Api.post('/two-factor-challenge', data)
      .then(response => {
        localStorage.setItem(window.location.origin + "-loggedin", true);
        store.commit("setHome", response.data);
        Api.cached().clearAll();
        this.websocketConnect();
        this.websocketListen();
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  },

  async logout() {
    await this.getCsrfToken();
    return await Api.post("/logout").then(response => {
      localStorage.removeItem(window.location.origin + "-loggedin");
      Api.cached().clearAll();
      store.dispatch("setNotifications", []);
      window.Echo.disconnect();
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  },

  async remoteAuthCheck() {
    return await this.home(true).then(response => {
      if (response.data.user) {
        localStorage.setItem(window.location.origin + "-loggedin", true);
        store.commit("setHome", response.data);
        this.websocketConnect();
        this.websocketListen();
        return true;
      }
      localStorage.removeItem(window.location.origin + "-loggedin");
      return false;
    }).catch(() => {
      localStorage.removeItem(window.location.origin + "-loggedin");
      return false;
    });
  },

  isLoggedIn() {
    if (localStorage.getItem(window.location.origin + "-loggedin") && Cookie.get("XSRF-TOKEN")) {
      return true;
    }
    return false;
  },

  getCsrfToken() {
    let token = Cookie.get("XSRF-TOKEN");
    if (token) {
      return Promise.resolve(token);
    }
    return Api.get("/csrf-cookie");
  },

	changeLanguage(user = null) {
		let language = "en"
		if (user) {
			language = user.language;
		} else {
			language = store.state.home.app.default_language;
		}
		changeLanguage(language)
	},

  loadNotifications() {
    store.dispatch("setNotifications");
  },
  websocketConnect() {
    window.Pusher = require('pusher-js');
    window.Pusher.Runtime.createXHR = function () {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr;
    }
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: '89c60f11-c289-4d3e-9122-95502ac670ca',
      authEndpoint: store.state.env.websocketAuthEndpoint,
      auth: {
        headers: {
          'X-XSRF-TOKEN': Cookie.get("XSRF-TOKEN"),
        },
      },
      withCredentials: true,
      wsHost: store.state.env.websocketHost,
      wsPort: store.state.env.websocketPort,
      wssPort: store.state.env.websocketPort,
      wsPath: store.state.env.websocketPath,
      forceTLS: false,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
    });
  },
  websocketListen(vue) {
    if (!vue) {
      vue = store.$app;
    }
    this.home().then((response) => {
      window.Echo.private(`user.${response.data.user.gravatar_hash}`)
        .notification((n) => {
          if (n.enabled) {
            store.dispatch("addNotification", {
              id: n.id,
              type: n.type,
              data: n,
              read_at: null,
            });
          }
          switch (n.type) {
            case 'user.instance.installation_finished':
              this.home(true);
              vue.$root.$emit("instance-installation-finished", n.instance);
              vue.$root.$emit("instance-item-changed", n.instance);
              break;
            case 'user.instance.installation_failed':
              vue.$root.$emit("instance-installation-failed", n.instance);
              vue.$root.$emit("instance-item-changed", n.instance);
              break;
            case 'user.instance.create_staging_finished':
              vue.$root.$emit("staging-install-finished", n.instance);
              break;
            case 'user.instance.create_staging_failed':
              vue.$root.$emit("staging-install-failed", n.instance);
              break;
            case 'user.instance.push_to_live_finished':
              vue.$root.$emit("push-to-live-finished", n.instance);
              break;
            case 'user.instance.push_to_live_failed':
              vue.$root.$emit("push-to-live-failed", n.instance);
              break;
            case 'user.instance.update_wordpress_finished':
              vue.$root.$emit("instance-update-finished", n.instance);
              break;
            case 'user.instance.update_wordpress_failed':
              vue.$root.$emit("instance-update-failed", n.instance);
              break;
            case 'user.instance.backup.create_finished':
            case 'user.instance.backup.create_failed':
            case 'user.instance.backup.restore_finished':
            case 'user.instance.backup.restore_failed':
            case 'user.instance.backup.delete_failed':
              vue.$root.$emit("backup-item-changed", n.backup);
              break;
            case 'user.instance.backup.delete_finished':
              vue.$root.$emit("backup-item-deleted", n.backup);
              break;
            case 'user.instance.plugin.install_finished':
            case 'user.instance.plugin.install_failed':
            case 'user.instance.plugin.update_finished':
            case 'user.instance.plugin.update_failed':
              vue.$root.$emit("plugin-item-changed", n.plugin);
              break;
            case 'user.instance.theme.install_finished':
            case 'user.instance.theme.install_failed':
            case 'user.instance.theme.update_finished':
            case 'user.instance.theme.update_failed':
              vue.$root.$emit("theme-item-changed", n.theme);
              break;
          }
          // store.dispatch("addAlert", {
          //   success: !n.is_error,
          //   html_text: n.html_text,
          // });
        });
    });
  },
};
