import Api from "@/apis/Api";

const NotificationsModule = {
  state: {
    notifications: [],
    total: 0,
    allNotificationsLoaded: false,
  },
  mutations: {
    applyNotifications: function (state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    addNotification: function (context, notification) {
      context.state.notifications.unshift(notification);
      context.state.total++;
    },
    removeNotification: function (context, id) {
      Api.delete(`/notifications/${id}`);
      let i = context.state.notifications.findIndex((n) => n.id == id);
      context.state.notifications.splice(i, 1);
      context.state.total--;
    },
    readAllNotifications: function (context) {
      const unmarkedAsReadNotifications = context.state.notifications.filter(
        (notification) => !notification.read_at
      );
      if (unmarkedAsReadNotifications.length) {
        let time = new Date().toISOString();
        Api.put(`/notifications/mark-as-read?read_at=${time}`);
        context.state.notifications = context.state.notifications.map(
          (item) => {
            return { ...item, read_at: time };
          }
        );
      }
    },
    removeAllNotifications: function (context) {
      if (context.state.notifications[0]) {
        let time = new Date().toISOString();
        Api.delete(`/notifications?delete_at=${time}`);
      }
      context.state.notifications = [];
      context.state.total = 0;
    },
    setNotifications: function (context) {
      Api.get("/notifications").then((response) => {
        context.state.notifications = response.data.data;
        context.state.total = response.data.meta.total;
      });
    },
    loadNextPageNotifications: function (context) {
      const numberOfNotifications = context.state.notifications.length;
      
      Api.get(
        "/notifications?page=" + (context.state.notifications.length / 10 + 1)
      ).then((response) => {
        context.state.notifications = context.state.notifications.concat(
          response.data.data
        );
        context.state.total = response.data.meta.total;
      });

      if (numberOfNotifications >= context.state.total) {
        context.state.allNotificationsLoaded = true;
      }
    },
  },
};

export default NotificationsModule;
