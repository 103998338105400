<template>
  <v-menu
    translate="slide-y"
    elevation="4"
    offset-y
    left
    nudge-bottom="16px"
    min-width="220px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        rounded
        large
        outlined
        class="pr-0"
        elevation="0"
        :height="$vuetify.breakpoint.mobile ? 40 : 48"
        color="gray"
        :class="$vuetify.breakpoint.mobile ? 'pl-0 ml-2' : 'pl-2 ml-4'"
        :min-width="$vuetify.breakpoint.mobile ? 0 : 82"
      >
        <v-icon
          v-if="!$vuetify.breakpoint.mobile"
          size="20"
          color="black"
          class=""
        >
          $chevrondown
        </v-icon>
        <user-avatar :size="$vuetify.breakpoint.mobile ? 40 : 48"></user-avatar>
      </v-btn>
    </template>
    <v-list class="dropdown-left v-list--small">
      <v-list-item class="primary--text" to="/my-account">
        <v-list-item-title class="p-2"
          ><v-icon size="20" class="mr-4 gray--icon">$user</v-icon>
          {{ $t('button.account.details') }}
        </v-list-item-title
        >
      </v-list-item>
      <!--
<v-list-item class="primary--text" to="/billing">
  <v-list-item-title class="p-2"
    ><v-icon size="20" class="mr-4 gray--icon">$creditcard</v-icon
    >Billing</v-list-item-title
  >
</v-list-item>
<v-list-item class="primary--text" to="/nodata">
  <v-list-item-title class="p-2"
    ><v-icon size="20" class="mr-4 gray--icon">$bell</v-icon
    >Notifications</v-list-item-title
  >
</v-list-item>
<v-divider></v-divider>
-->
      <v-list-item class="primary--text" @click="logout">
        <v-list-item-title class="p-2"
          ><v-icon size="20" class="mr-4 gray--icon">$logout</v-icon>
          {{ $t('button.logout') }}
        </v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import UserAvatar from "../UserAvatar.vue";
import Auth from "@/apis/Auth";

export default {
  components: {
    UserAvatar,
  },
  methods: {
    logout() {
      Auth.logout()
        .then(() => {
          this.$root.$emit("loggedOut", true);
          this.$router.push({ path: "/login" });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn::v-deep {
  .v-icon {
    svg {
      transition: color 0.24s ease;
    }
  }
  &:hover {
    .v-icon {
      svg {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
</style>
