<template>
  <v-card class="basic-modal" elevation="4">
    <v-card-title class="d-flex justify-space-between">
      <h5 class="heading--text d-flex align-center">
        <v-icon
          size="24"
          class="mr-4"
          v-if="modalOptions.icon"
          :color="modalOptions.color ? modalOptions.color : 'primary'"
        >{{ modalOptions.icon }}
        </v-icon
        >
        <span v-html="modalOptions.title"></span>
      </h5>
      <v-btn
        v-if="!modalOptions.submitting"
        icon
        x-small
        @click="$emit('modal-close')"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn
      >
    </v-card-title>

    <pre>{{ modalOptions.submittingProgressLog }}</pre>

    <!-- <loader :color="color ? color : 'primary'" v-if="modalOptions.loading" /> -->

    <v-card-text>
      <div>
        <!-- <p v-if="modalOptions.submittingError" class="mb-2 error--text">
          {{ modalOptions.submittingError }}
        </p> -->
        <!-- <p v-else-if="modalOptions.submittingSuccess" class="mb-2 primary--text">
          {{ modalOptions.submittingSuccess }}
        </p> -->
        <p
          v-if="modalOptions.message"
          class="mb-4 p-2"
          :class="modalOptions.messageClass"
          v-html="modalOptions.message"
        ></p>

        <slot name="custom" v-bind="{ item: modalOptions.item }"></slot>

        <v-form
          ref="form"
          class="d-flex flex-column"
          v-if="modalOptions.formFields.length"
        >
          <div
            v-for="(field, key) in modalOptions.formFields"
            :key="key"
            class="d-flex flex-column"
          >
            <label
              v-if="(field.label || field.tooltip) && field.type !== 'checkbox'"
              class="base--text mb-2"
              v-show="!field.hidden"
            >{{ field.label }}
              <form-help-icon :text="$t(field.tooltip)"
              />
            </label>

            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-if="field.type == 'text'"
              v-show="!field.hidden"
            >
              <v-text-field
                v-if="!field.loading"
                outlined
                :placeholder="field.placeholder"
                v-model="formValues[field.name]"
                class="v-input--md"
                hide-details="auto"
                validate-on-blur
                dense
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || $t('form.validation.required', {
                            field: field.label
                          })
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
                :readonly="field.readonly"
                :prefix="field.prefix"
                :suffix="field.suffix"
              ></v-text-field>
              <v-skeleton-loader v-else type="text"/>
            </div>
            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-if="field.type == 'multiText'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-text-field
                v-for="(input, inputKey) in field.inputs"
                :key="inputKey"
                outlined
                :placeholder="input.placeholder"
                v-model="formValues[input.name]"
                class="v-input--md"
                hide-details="auto"
                validate-on-blur
                dense
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || $t('form.validation.required', {
                            field: field.label
                          })
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
                :readonly="input.readonly"
                :prefix="input.prefix"
                :suffix="input.suffix"
                @input="field.onInput ? field.onInput(formValues) : null"
              ></v-text-field>
            </div>

            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-else-if="field.type == 'text-select'"
              v-show="!field.hidden"
            >
              <text-select-input
                v-if="!field.loading"
                :suffix="field.suffix"
                :text.sync="formValues[field.textName]"
                :select.sync="formValues[field.selectName]"
                :availableSelectOptions="field.options"
                :readonly="field.options.length < 2"
                :placeholder="field.placeholder"
                :label="field.label"
                :rules="field.hidden ? [] : field.rules"
                :required="field.hidden ? false : field.required"
                :value="
                  formValues[field.textName] + formValues[field.selectName]
                "
                @update:value="field.onChange ? field.onChange : null"
              />
              <v-skeleton-loader v-else type="text"/>
            </div>

            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-else-if="field.type == 'select'"
              v-show="!field.hidden"
            >
              <v-select
                v-if="!field.loading"
                outlined
                v-model="formValues[field.name]"
                :items="field.options"
                :multiple="field.multiple"
                item-text="label"
                hide-details="auto"
                item-value="value"
                dense
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || $t('form.validation.required', {
                            field: field.label
                          })
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
                :readonly="field.readonly"
                :append-icon="!field.readonly ? '$dropdown' : ''"
                :menu-props="{
                  offsetY: true,
                }"
                @change="field.onChange ? field.onChange(formValues) : null"
              >
                <template v-if="field.name === 'role'" v-slot:item="{ item }">
                  <user-role-label :role="item.value"></user-role-label>
                </template>
                <template
                  v-if="field.name === 'role'"
                  v-slot:selection="{ item }"
                >
                  <user-role-label :role="item.value"></user-role-label>
                </template>
              </v-select>
              <v-skeleton-loader v-else type="text"/>
            </div>

            <div
              :class="[
                key + 1 === modalOptions.formFields.length ? 'last-child' : '',
                field.hidden ? '' : 'd-flex flex-column align-start w-100 pb-4',
              ]"
              v-else-if="field.type == 'checkbox'"
              v-show="!field.hidden"
            >
              <template v-if="!field.hidden">
                <template v-if="!field.loading">
                  <p
                    v-if="field.message"
                    class="p-2"
                    :class="field.messageClass"
                    v-html="field.message"
                  ></p>
                  <label
                    class="base--text checkbox-label d-flex align-center p-2"
                  >
                    <checkbox
                      outlined
                      @change="
                        checkboxCheck(field);
                        field.onChange ? field.onChange(formValues) : null;
                      "
                      :value="formValues[field.name]"
                      :checked="formValues[field.name]"
                      :label="field.label"
                      :tooltip="$t(field.tooltip)"
                      :color="color"
                      dense
                      :rules="
                        field.hidden
                          ? []
                          : [
                              field.required
                                ? (value) =>
                                  !!value || $t('form.validation.required', {
                                    field: field.label
                                  })
                                  : () => true,
                              ...spreadRules(field.rules),
                            ]
                      "
                    ></checkbox>
                  </label>
                </template>
                <v-skeleton-loader v-else type="text" class="w-100"/>
              </template>
            </div>

            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-else-if="field.type == 'textarea'"
              v-show="!field.hidden"
            >
              <p
                v-if="field.message"
                class="p-2"
                :class="field.messageClass"
                v-html="field.message"
              ></p>
              <v-textarea
                v-if="!field.loading"
                outlined
                hide-details="auto"
                :height="field.height"
                autoGrow
                v-model="formValues[field.name]"
                :placeholder="field.placeholder"
                dense
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || $t('form.validation.required', {
                            field: field.label
                          })
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
              ></v-textarea>
              <v-skeleton-loader v-else type="text@3"/>
            </div>

            <div
              v-else-if="field.type == 'generatePassword'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-text-field
                v-model="formValues[field.name]"
                outlined
                dense
                hide-details="auto"
                validate-on-blur
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || $t('form.validation.required', {
                            field: field.label
                          })
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
                class="v-input--md"
                :type="showPassword ? 'text' : 'password'"
                :autofocus="false"
                autocomplete="new-password"
                v-show="!field.hidden"
              ></v-text-field>
              <v-btn
                icon
                tile
                outlined
                large
                color="gray"
                class="mr-4 ml-4"
                @click="showPassword = !showPassword"
                v-show="!field.hidden"
              >
                <v-icon color="black">{{
                    showPassword ? "$eye" : "$eyeclosed"
                  }}
                </v-icon>
              </v-btn>
              <v-btn
                @click.prevent="formValues[field.name] = generatePassword()"
                large
                outlined
                color="gray"
                v-show="!field.hidden"
              >
                <span class="black--text">{{ $t('button.password.generate') }}</span>
              </v-btn>
            </div>
            <div
              v-else-if="field.type == 'password'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-text-field
                outlined
                dense
                hide-details="auto"
                validate-on-blur
                class="v-input--md"
                :type="showPassword ? 'text' : 'password'"
                :autofocus="false"
                autocomplete="new-password"
                :rules="
                  field.hidden
                    ? []
                    : [
                        field.required
                          ? (value) => !!value || `${field.label} Required.`
                          : () => true,
                        ...spreadRules(field.rules),
                      ]
                "
                v-show="!field.hidden"
                v-model="formValues[field.name]"
              />
              <v-btn
                icon
                tile
                outlined
                large
                color="gray"
                class="mr-0 ml-4"
                @click="showPassword = !showPassword"
                v-show="!field.hidden"
              >
                <v-icon color="black">{{
                    showPassword ? "$eye" : "$eyeclosed"
                  }}
                </v-icon>
              </v-btn>
            </div>
            <div
              v-else-if="field.type == 'hiddenPassword'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-text-field
                outlined
                dense
                hide-details="auto"
                validate-on-blur
                class="v-input--md"
                :type="'password'"
                :autofocus="false"
                autocomplete="new-password"
                v-show="!field.hidden"
                disabled
                value="************"
              ></v-text-field>
              <v-btn
                @click.prevent="field.onButtonClick()"
                large
                outlined
                color="gray"
                class="ml-4"
                v-show="!field.hidden"
              >
                <span class="black--text">{{ $t('button.password.setNew') }}</span>
              </v-btn>
            </div>
            <div
              v-else-if="field.type == 'switch'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-switch
                v-model="formValues[field.name]"
                v-show="!field.hidden"
                outlined
                dense
                hide-details="auto"
                validate-on-blur
                class="pa-0 ma-0"
                inset
              />
            </div>
            <span v-if="field.description" class="mb-3"
            ><strong>{{ field.description }}</strong></span
            >
          </div>
        </v-form>
        <div v-if="modalOptions.affixLink && !modalOptions.affixLink.hidden">
          <component
            class="info--text"
            :is="!modalOptions.affixLink.external ? 'router-link' : 'a'"
            :href="
              modalOptions.affixLink.external
                ? modalOptions.affixLink.url
                : undefined
            "
            :to="
              !modalOptions.affixLink.external
                ? modalOptions.affixLink.url
                : undefined
            "
          >
            {{ modalOptions.affixLink.message }}
          </component>
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      :class="modalOptions.actionsClasses"
      class="d-flex flex-wrap pb-4"
    >
      <div
        class="w-100 pb-2"
        v-for="(button, key) in modalOptions.buttons"
        :key="key"
      >
        <v-btn
          :text="button.color == 'normal' ? true : false"
          large
          block
          elevation="0"
          :class="button.color"
          @click="!modalOptions.submitting && handleButtonClick(button)"
          :loading="
            (modalOptions.submitting || modalOptions.loading) &&
            button.type !== `cancel`
          "
          :disabled="
            hasRequiredCheckboxes &&
            button.type !== 'cancel' &&
            !formValues.confirm
          "
        >
          <template v-slot:loading>
            <loader/>
          </template>
          <span
            class="p-2"
            :class="
              button.color == 'normal'
                ? 'font-weight-light gray--text text--darken-1'
                : 'font-weight-600'
            "
          >{{ button.label }}</span
          >
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormRulesMixin from "../../mixins/FormRulesMixin";
import FormHelpIcon from "../FormHelpIcon.vue";
import Loader from "@/components/Loader.vue";
import Checkbox from "../buttons/Checkbox.vue";
import TextSelectInput from "../inputs/TextSelectInput.vue";
import UserRoleLabel from "../labels/UserRoleLabel.vue";

export default {
  mixins: [FormRulesMixin],
  components: {
    FormHelpIcon,
    Loader,
    Checkbox,
    TextSelectInput,
    UserRoleLabel,
  },
  data: function () {
    let formValues = {...this.modalOptions.formValues};
    if (this.modalOptions.item) {
      formValues = {...this.modalOptions.item};
    }
    for (const field of this.modalOptions.formFields) {
      if (typeof field.name === "undefined") {
        continue;
      }
      if (typeof formValues[field.name] === "undefined") {
        formValues[field.name] = null;
      }
    }
    return {
      formValues: formValues,
      showPassword: false,
    };
  },
  props: {
    modalOptions: Object,
    instance: Object,
  },
  mounted() {
    if (this.modalOptions.onMount) {
      this.modalOptions.onMount();
    }
  },
  methods: {
    spreadRules(rules) {
      if (
        rules &&
        Array.isArray(rules) &&
        rules.length > 0 &&
        rules.filter((rule) => typeof rule === "function").length ===
        rules.length
      ) {
        return rules;
      }
      return [];
    },
    setValue(name, value) {
      this.formValues[name] = value;
    },
    setValues(values) {
      for (const value in values) {
        this.setValue(value, values[value]);
      }
    },
    showFields(names) {
      for (const field of this.modalOptions.formFields) {
        if (names.includes(field.name)) {
          field.hidden = false;
        }
      }
    },
    hideFields(names) {
      for (const field of this.modalOptions.formFields) {
        if (names.includes(field.name)) {
          field.hidden = true;
        }
      }
    },
    handleButtonClick(button) {
      button.onclick(this);
    },
    checkboxCheck: function (item) {
      if (item.name === "confirm") {
        this.$set(this.formValues, "confirm", !this.formValues["confirm"]);
      } else {
        this.formValues[item.name] = !this.formValues[item.name];
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    generateRandomString(chars, length) {
      let random = "";
      for (let i = 0; i < length; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        random += chars.substring(randomNumber, randomNumber + 1);
      }
      return random;
    },
    generatePassword() {
      return this.generateRandomString(
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        10
      );
    },
  },
  beforeMount: function () {
    if (!this.color) {
      this.color = "primary";
    }
    if (!this.hasConfirmCheckbox) {
      this.hasConfirmCheckbox = false;
    }
  },
  computed: {
    hasRequiredCheckboxes: function () {
      const requiredCheckBoxes = this.modalOptions.formFields.filter((item) => {
        if (item.required && item.type == "checkbox") {
          return item;
        }
      });

      return requiredCheckBoxes.length > 0;
    },
  },
};
</script>

<style src="./BasicModal.scss" lang="scss" scoped/>
