<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.13 6.82C16.66 5.58 14.07 5.25 12.28 6.84L9.32999 9.46L6.37999 12.08C5.44999 12.91 3.95999 12.74 3.14999 12.06C2.30999 11.35 1.98999 10.05 2.42999 9.09C3.00999 7.83 4.11999 7.57 4.94999 7.57C5.82999 7.57 6.55999 8.25 7.15999 8.79C7.29999 8.92 7.43999 9.04 7.55999 9.15L8.84999 7.62C8.74999 7.53 8.62999 7.43 8.50999 7.32C7.75999 6.63 6.61999 5.58 4.96999 5.57H4.92999C2.96999 5.57 1.39999 6.54 0.61999 8.25C-0.19001 10.01 0.33999 12.31 1.86999 13.59C2.64999 14.24 3.72999 14.64 4.84999 14.64C5.84999 14.64 6.86999 14.32 7.71999 13.57L10.67 10.95L13.62 8.33C14.55 7.5 16.04 7.67 16.85 8.35C17.69 9.06 18.01 10.36 17.57 11.32C16.99 12.58 15.87 12.84 15.05 12.84C14.17 12.84 13.44 12.16 12.84 11.62C12.7 11.49 12.56 11.37 12.44 11.26L11.15 12.79C11.25 12.88 11.37 12.98 11.49 13.09C12.24 13.78 13.38 14.83 15.03 14.84H15.07C17.03 14.84 18.6 13.87 19.39 12.16C20.2 10.4 19.67 8.1 18.14 6.82H18.13Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>