var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"translate":"slide-y","elevation":"0","open-on-hover":"","open-delay":"200","offset-x":"","nudge-top":"50%","nudge-left":"16px","left":"","center":"","min-width":"120px","content-class":"elevation-4--before elevation-4--after elevation-4"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"status-indicator-container"},[(!_vm.hasActionPending)?_c('div',_vm._g(_vm._b({class:`status-indicator status-indicator--${
          _vm.instance.status.value ? 'ok' : 'warning'
        } ${_vm.sameSize ? 'status-indicator--samesize' : '1'}`},'div',attrs,false),on),[(_vm.instance.status.value)?_c('v-icon',{staticClass:"success--text",attrs:{"size":"20"}},[_vm._v(" $okcircle ")]):_c('v-icon',{staticClass:"error--text",attrs:{"size":"16"}},[_vm._v(" $alertwarningfilled ")])],1):_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('loader',{attrs:{"noMargins":true,"size":20,"color":"primary"}})],1)])]}}])},[[_c('div',{staticClass:"status-list"},[(_vm.statusObject.wordpress.status !== 'not-installed')?_c('div',{staticClass:"d-flex flex-column status-list__group"},[_c('span',{staticClass:"p-4 status-list__title"},[_vm._v(_vm._s(_vm.$t('general.wordpress')))]),_c('span',{staticClass:"status-list__value d-flex align-center",class:`${
            _vm.statusObject.wordpress.status ? 'success' : 'error'
          }--text`},[(!_vm.hasWordpressAction)?_c('v-icon',{staticClass:"status-list__icon",attrs:{"color":_vm.statusObject.wordpress.status ? 'success' : 'error',"size":"16"}},[_vm._v(" "+_vm._s(_vm.statusObject.wordpress.status ? "$alertok" : "$alertwarningfilled")+" ")]):_c('loader',{staticClass:"mr-2",staticStyle:{"display":"inline"},attrs:{"color":'primary',"size":16,"noMargins":true}}),_vm._v(" "+_vm._s(_vm.statusObject.wordpress.value)+" ")],1)]):_vm._e(),(_vm.statusObject.woocommerce.status !== 'not-installed')?_c('div',{staticClass:"d-flex flex-column status-list__group"},[_c('span',{staticClass:"p-4"},[_vm._v(_vm._s(_vm.$t('general.woocommerce')))]),_c('span',{staticClass:"status-list__value d-flex align-center",class:`${
            _vm.statusObject.woocommerce.status ? 'success' : 'error'
          }--text`},[(!_vm.hasWoocommerceAction)?_c('v-icon',{staticClass:"status-list__icon",attrs:{"color":_vm.statusObject.woocommerce.status ? 'success' : 'error',"size":"16"}},[_vm._v(" "+_vm._s(_vm.statusObject.woocommerce.status ? "$alertok" : "$alertwarningfilled")+" ")]):_c('loader',{staticClass:"mr-2",staticStyle:{"display":"inline"},attrs:{"color":'primary',"size":16,"noMargins":true}}),_vm._v(" "+_vm._s(_vm.statusObject.woocommerce.value)+" ")],1)]):_vm._e(),_c('div',{staticClass:"d-flex flex-column status-list__group"},[_c('span',{staticClass:"p-4 status-list__title"},[_vm._v(_vm._s(_vm.$t('general.backup')))]),_c('span',{staticClass:"status-list__value d-flex align-start",class:`${
            _vm.instance.hasAutomaticBackup() && _vm.instance.hasRecentBackup()
              ? 'success'
              : 'error'
          }--text`},[(!_vm.hasBackupAction)?[_c('v-icon',{staticClass:"status-list__icon mt-1",attrs:{"color":_vm.instance.hasAutomaticBackup() && _vm.instance.hasRecentBackup()
                  ? 'success'
                  : 'error',"size":"16"}},[_vm._v(" "+_vm._s(_vm.instance.hasAutomaticBackup() && _vm.instance.hasRecentBackup() ? "$alertok" : "$alertwarningfilled")+" ")]),_c('div',{staticClass:"d-flex flex-column"},[(!_vm.instance.hasRecentBackup())?_c('span',{class:!_vm.instance.hasRecentBackup() &&
                  !_vm.instance.hasAutomaticBackup()
                    ? 'mb-1'
                    : ''},[_vm._v(_vm._s(_vm.$t('message.noRecentBackups'))+" ")]):_vm._e(),(!_vm.instance.hasAutomaticBackup())?_c('span',[_vm._v(_vm._s(_vm.$t('message.automaticBackupsDisabled'))+" ")]):_vm._e(),(_vm.instance.hasAutomaticBackup() && _vm.instance.hasRecentBackup())?_c('span',[_vm._v(_vm._s(_vm.$t('message.status.enabled')))]):_vm._e()])]:[_c('loader',{staticClass:"mr-2",staticStyle:{"display":"inline"},attrs:{"color":"success","size":16,"noMargins":true}}),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('message.status.action.inProgress')))])]],2)])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }