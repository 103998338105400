<template>
  <div
    class="user-info"
    :class="$vuetify.breakpoint.width < 475 ? 'flex-column align-start' : ''"
  >
    <div
      class="d-flex align-center"
      :class="$vuetify.breakpoint.width < 475 ? 'ml-4' : ''"
    >
      <v-avatar size="32px" style="align-self: center">
        <v-img :src="getAvatarUrl(item.email, 48)" />
      </v-avatar>
      <div class="d-flex flex-column justify-center ml-3">
        <p
          class="
            p-2
            mb-0
            font-weight-bold
            gray--text
            text--darken-2
            d-flex
            primary-onhover
          "
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'flex-column align-start'
              : 'align-center'
          "
        >
          <span v-if="isYou">{{ $t('table.field.userOwner', {name: item.name}) }}</span>
          <span v-else>{{ item.name }}</span>
          <template v-if="item.invitationPending">
            <v-tooltip open-delay="150" bottom transition="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <StatusLabel
                    small
                    :status="'info'"
                    :value="$t('message.status.pending')"
                    :class="$vuetify.breakpoint.smAndDown ? 'my-2' : 'ml-2'"
                  />
                </div>
              </template>
              <div class="d-flex align-center">
                <v-icon size="16" class="mr-2">$hourglassIcon</v-icon>
                {{ $t('tooltip.sharedAccess.invited', {time: item.invited_at_text}) }}
              </div>
            </v-tooltip>
            <!-- <span
              class="user-info__pending-invite primary-onhover"
              :class="$vuetify.breakpoint.smAndDown ? 'ml-0' : ''"
            >
              <v-icon size="12" class="primary-onhover mr-1">$clock</v-icon>
              
            </span> -->
          </template>
        </p>
        <p
          class="p-3 mb-0 mt-1 gray--text text--darken-1 primary-onhover"
          v-if="item.name !== item.email"
        >
          {{ item.email }}
        </p>
      </div>
      <div
        class="justify-self-end"
        :class="$vuetify.breakpoint.width < 475 ? '' : 'ml-1'"
      >
        <v-tooltip open-delay="150" bottom transition="custom-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                v-if="item.invitationPending"
                text
                class="d-flex justify-center align-center font-weight-600"
                :class="hasPendingChanges ? 'error--text' : 'info-onhover'"
                style="margin-right: -16px"
                @click="$emit('resend-button-clicked', item)"
                :loading="resending"
                icon
                small
                :disabled="!resendAvailable"
              >
                <v-icon size="20"> $restore </v-icon>
              </v-btn>
            </div>
          </template>
          <span class=""> {{ resendButtonTooltipText }} </span>
        </v-tooltip>
      </div>
    </div>

    <div
      class="justify-self-end"
      :class="$vuetify.breakpoint.width < 475 ? '' : 'ml-auto'"
      v-if="mixedRoles"
    >
      {{ $t('heading.instance.modal.sharedAccess.mixedTole.title') }}
    </div>
    <div
      class="justify-self-end d-flex align-center justify-space-between"
      style="width: 223px"
      :class="$vuetify.breakpoint.width < 475 ? '' : 'ml-auto'"
    >
      <select-dropdown
        :outlined="false"
        :value="item.role"
        :itemText="'text'"
        :itemValue="'key'"
        :items="roles"
        :taggable="true"
        class="ml-4 mt-0 pt-0"
        @update:value="handleAccessSettingChange"
      >
      </select-dropdown>
      <v-tooltip open-delay="150" bottom transition="custom-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            @click="removeAccess"
            small
            icon
            class="error-onhover"
          >
            <v-icon size="20">$xcircle</v-icon>
          </v-btn>
        </template>

        {{ $t('tooltip.sharedAccess.removeAccess') }}
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import SelectDropdown from "../SelectDropdown.vue";
import StatusLabel from "../StatusLabel.vue";
import { getAvatarUrl } from "@/apis/HelperFunctions";
import moment from "moment";

export default {
  emits: ["role-changed", "user-remove-access"],
  components: {
    SelectDropdown,
    StatusLabel,
  },
  props: {
    roles: Array,
    item: Object,
    hasPendingChanges: Boolean,
    mixedRoles: {
      type: Boolean,
      default: false,
    },
    resending: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      getAvatarUrl,
    };
  },
  computed: {
    resendAvailable() {
      return (
        this.item.resend_available_at &&
        moment(this.item.resend_available_at).isBefore()
      );
    },
    resendButtonTooltipText() {
      if (this.resendAvailable) {
        return this.$t('tooltip.sharedAccess.resend');
      }
      return this.$t('tooltip.sharedAccess.resendNotAvailable', {
        email: this.item.email,
        time: moment(this.item.resend_available_at).fromNow()
      })
    },
    isYou() {
      return this.$store.state.home.user.email == this.item.email;
    },
  },
  methods: {
    removeAccess: function () {
      this.$emit("user-remove-access", this.item);
    },
    handleAccessSettingChange: function (event) {
      // if (this.item.role == event.key) {
      //   return;
      // }
      this.$emit("role-changed", this.item, event.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown-select .v-list-item::before {
  background: currentColor !important;
}
.user-info {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 62px;
  padding: 8px 16px 8px 8px;
  transition: background-color 0.24s ease;
  .primary-onhover {
    transition: color 0.24s ease;
  }

  &:hover {
    background-color: #f9f9fb;
    border-radius: 4px;
    .primary-onhover {
      color: var(--v-primary-base) !important;
    }
  }

  .user-info__pending-invite {
    color: var(--v-text-lighten1);
    margin-left: 8px;
    font-weight: $font-weight-light;
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    display: flex;
    align-items: center;
  }
}

.v-btn::v-deep {
  .v-btn__content {
    font-weight: $font-weight-semibold;
  }
}

.input-dropdown::v-deep {
  border: none !important;
  .v-select__selections .v-select__selection {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-semibold;
    color: var(--v-text-base);
    margin: 0px;
  }
  & > .v-input__control > .v-input__slot {
    &:after,
    &:before {
      display: none;
    }
  }
}

.error-onhover {
  &:hover {
    color: var(--v-error-base);
  }
}

.info-onhover {
  &:hover {
    color: var(--v-info-base);
  }
}

.v-avatar {
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
