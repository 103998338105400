<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8909 14.909C18.8909 15.7177 18.2743 16.3876 17.4875 16.4726H17.309H2.79087C1.92497 16.4726 1.21814 15.7658 1.21814 14.8999V5.02716C1.21814 4.19762 1.8977 3.51807 2.72723 3.51807H6.42723C6.71118 3.51807 6.96481 3.62441 7.15649 3.81608L7.15648 3.81609L7.15991 3.81949L8.10537 4.75585L8.18162 4.83138L8.27217 4.889L8.80905 4.04534C8.27217 4.889 8.27229 4.88908 8.27241 4.88915L8.27265 4.88931L8.27315 4.88962L8.27417 4.89027L8.27636 4.89165L8.28132 4.89476L8.29352 4.90227C8.3026 4.9078 8.31388 4.91452 8.32697 4.92206C8.35229 4.93663 8.38865 4.95666 8.43225 4.97771C8.43351 4.97832 8.435 4.97904 8.43671 4.97987C8.48408 5.00298 8.70145 5.10898 8.97269 5.10898H17.3181C18.184 5.10898 18.8909 5.81581 18.8909 6.6817V14.909Z" stroke="currentColor" stroke-width="2"/>
    <path d="M4.37052 12.5994C4.37052 12.5994 4.34121 12.5994 4.33144 12.5994C4.08723 12.5799 3.88209 12.4334 3.78441 12.2087L2.66104 9.47354C2.52428 9.15118 2.68058 8.77998 3.0127 8.64322C3.33506 8.50646 3.70626 8.66276 3.84302 8.99488L4.4682 10.5285L4.60496 10.2843C4.72218 10.0792 4.91755 9.95219 5.16176 9.96196C5.3962 9.96196 5.60134 10.0889 5.70879 10.2941L5.82601 10.509L6.50003 8.97535C6.63679 8.65299 7.01776 8.50646 7.34011 8.65299C7.66247 8.78975 7.809 9.17071 7.66247 9.49307L6.46096 12.2282C6.36327 12.4529 6.14837 12.5994 5.90416 12.6092C5.64041 12.6092 5.43527 12.492 5.31805 12.2771L5.12268 11.9156L4.90778 12.2868C4.79056 12.4822 4.58542 12.6092 4.36075 12.6092L4.37052 12.5994Z" fill="currentColor"/>
    <path d="M9.26444 12.5994C9.26444 12.5994 9.23513 12.5994 9.22536 12.5994C8.98115 12.5799 8.77601 12.4334 8.67833 12.2087L7.55496 9.47354C7.4182 9.15118 7.5745 8.77998 7.90663 8.64322C8.23875 8.50646 8.60018 8.66276 8.73694 8.99488L9.36212 10.5285L9.49888 10.2843C9.6161 10.0792 9.78216 9.95219 10.0557 9.96196C10.2901 9.96196 10.4953 10.0889 10.6027 10.2941L10.7199 10.509L11.394 8.97535C11.5307 8.65299 11.9117 8.50646 12.234 8.65299C12.5564 8.78975 12.7029 9.17071 12.5564 9.49307L11.3549 12.2282C11.2572 12.4529 11.0423 12.5994 10.7981 12.6092C10.5343 12.6092 10.3292 12.492 10.212 12.2771L10.0166 11.9156L9.8017 12.2868C9.68448 12.4822 9.47934 12.6092 9.25467 12.6092L9.26444 12.5994Z" fill="currentColor"/>
    <path d="M14.1585 12.5994C14.1585 12.5994 14.1292 12.5994 14.1194 12.5994C13.8752 12.5799 13.6701 12.4334 13.5724 12.2087L12.449 9.47354C12.3122 9.15118 12.4685 8.77998 12.8007 8.64322C13.123 8.50646 13.4942 8.66276 13.631 8.99488L14.2562 10.5285L14.3929 10.2843C14.5101 10.0889 14.7153 9.96196 14.94 9.96196C15.1744 9.96196 15.3795 10.0889 15.487 10.2941L15.6042 10.509L16.2782 8.97535C16.415 8.65299 16.796 8.50646 17.1183 8.65299C17.4407 8.78975 17.5872 9.17071 17.4407 9.49307L16.2392 12.2282C16.1415 12.4529 15.9266 12.5994 15.6824 12.6092C15.4577 12.6092 15.2135 12.492 15.0962 12.2771L14.9009 11.9156L14.686 12.2868C14.5688 12.4822 14.3636 12.6092 14.1389 12.6092L14.1585 12.5994Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>