import Api from "../apis/Api"

const InstanceModule = {
    state: {
        nameChangingActionInProgress: false,
        instanceName: ""
    },
    actions: {
        updateSiteName: function (context, data) {
            context.state.nameChangingActionInProgress = true;
            data.instance.updateSiteName(data.newName)
                .then(() => {
                    context.dispatch("addAlert", {
                        successMessage: "Instance name changed successfuly",
                        success: true,
                    });
                    context.state.nameChangingActionInProgress = false;
                })
                .catch((error) => {
                    context.dispatch("addAlert", {
                        success: false,
                        errorMessage: Api.getErrorMessage(error),
                    });
                    context.state.nameChangingActionInProgress = false;
                });
        },
        setInstanceName(context, name) {
            context.state.instanceName = name;
        }
    },
    getters: {
        getInstanceTitle(state) {
            return state.instanceName;
        }
    }
}

export default InstanceModule