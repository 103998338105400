<template>
  <svg
    v-if="$parent.size == '16'"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8354_11778)">
      <path
        d="M15.5 8.02002C15.5 12.1622 12.1421 15.52 8 15.52C3.85786 15.52 0.5 12.1622 0.5 8.02002C0.5 3.87788 3.85786 0.52002 8 0.52002C12.1421 0.52002 15.5 3.87788 15.5 8.02002Z"
        stroke="currentColor"
      />
    </g>
    <path
      d="M8 5.02002V8.02002"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="8"
      cy="11.02"
      r="0.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <defs>
      <clipPath id="clip0_8354_11778">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0200195)"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="8.5" :stroke="iconColor" />
    <path
      d="M10 6.5V9"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="10" cy="13" r="1" :fill="iconColor" />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style></style>
