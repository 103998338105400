const breakpointsSettings = {
    thresholds: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1216,
        xl: 1416
    },
    mobileBreakpoint: 'md'
}

export default breakpointsSettings