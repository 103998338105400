<template>
  <v-app>
    <error-page />
  </v-app>
</template>

<script>
import ErrorPage from "./views/ErrorPage.vue";

export default {
  components: {
    ErrorPage,
  },
  name: "ConnectionError",
};
</script>
