<template>
  <div>
    <svg
      v-if="$parent.size === '20'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6023 1.66667 9.99996 1.66667C5.39759 1.66667 1.66663 5.39763 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M14.9878 4.16667C14.5697 4.44158 14.2769 4.99093 14.2769 5.51065C14.2769 6.19769 14.6691 6.79902 15.1048 7.48651C15.4536 8.04498 15.8019 8.77487 15.8019 9.80611C15.8019 10.5365 15.5841 11.4382 15.1483 12.5552L14.2769 15.3899L11.1828 6.28385C11.7059 6.241 12.1851 6.19815 12.1851 6.19815C12.6209 6.15529 12.5774 5.51111 12.1417 5.51111C12.1417 5.51111 12.1417 5.51111 12.0982 5.51111C12.0982 5.51111 10.7036 5.64013 9.78861 5.64013C8.96062 5.64013 7.52251 5.51111 7.52251 5.51111C7.52251 5.51111 7.52251 5.51111 7.47903 5.51111C7.0433 5.51111 6.99982 6.19815 7.43555 6.19815C7.43555 6.19815 7.87128 6.241 8.35096 6.28385L9.70211 9.89182L7.82827 15.4328L4.69024 6.241C5.2134 6.19815 5.69261 6.15529 5.69261 6.15529C6.12835 6.11244 6.08487 5.46826 5.64913 5.46826C5.64913 5.46826 5.64913 5.46826 5.60565 5.46826C5.60565 5.46826 4.55979 5.57174 3.71423 5.60183C3.14343 6.33218 2.77476 7.03699 2.5 7.94332L5.77587 16.7562C6.37721 17.147 7.03081 17.4519 7.73252 17.6667L10.0509 10.9659L12.5261 17.6065C13.2495 17.3744 13.9295 17.0494 14.5396 16.6199L16.8492 10.0213C17.1933 9.16238 17.4218 8.28615 17.5 7.61598C17.1572 6.47442 16.0711 4.90842 14.9878 4.16667Z"
        :fill="iconColor"
      />
    </svg>

    <svg
      v-else
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12C4 15.166 5.84 17.903 8.509 19.2L4.692 8.744C4.249 9.738 4 10.84 4 12ZM17.407 11C17.407 10.25 17.054 9.917 16.741 9.389C16.346 8.723 15.955 8.172 15.955 7.513C15.955 6.778 16.513 6.094 17.298 6.094C17.333 6.094 17.367 6.098 17.402 6.1C15.978 4.796 14.082 4 12 4C9.205 4 6.746 5.434 5.316 7.607C5.504 7.613 5.681 7.616 5.831 7.616C6.668 7.616 7.963 7.514 7.963 7.514C8.394 7.489 8.445 8.123 8.014 8.174C8.014 8.174 7.58 8.224 7.099 8.25L9.75 16.189L11.501 10.938L10.517 8.249C10.086 8.224 9.677 8.173 9.677 8.173C9.245 8.147 9.296 7.487 9.727 7.513C9.727 7.513 11.048 7.615 11.834 7.615C12.671 7.615 13.966 7.513 13.966 7.513C14.398 7.488 14.448 8.122 14.017 8.173C14.017 8.173 13.582 8.223 13.102 8.249L16 16.253L16.799 13.587C17.062 12.521 17.407 11.686 17.407 11ZM12.141 12.7L9.74 19.674C10.457 19.885 11.215 20 12 20C12.931 20 13.825 19.839 14.657 19.547C14.636 19.512 14.616 19.476 14.599 19.437L12.141 12.7ZM19.02 8.161C19.055 8.416 19.06 8.689 19.074 8.984C19.114 9.798 18.947 11.03 18.756 11.622C18.146 13.509 16.33 18.702 16.33 18.702C16.36 18.691 16.427 18.663 16.557 18.574C18.647 17.143 20 14.724 20 12C20 10.608 19.645 9.3 19.02 8.161Z"
        :fill="iconColor"
      />
      <path
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
        :stroke="iconColor"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
</style>