const themeSettings = {
    options: {
        customProperties: true
    },
    themes: {
        light: {
            primary: {
                base: "#07C07E",
                lighten1: '#1FD895',
                lighten2: '#CDF2E5',
                lighten4: '#E6F9F2',
                darken1: '#0AAA71'
            },
            gray: {
                base: '#CBCBDC',
                darken1: '#797997',
                darken2: '#49495F',
                lighten1: '#9494AD',
                lighten2: '#E5E5F0',
                lighten3: '#E5E5F0',
                lighten4: '#E5E5F0',
                lighten5: '#F2F2F7',
                lighten6: '#F9F9FB',
            },
            // secondary: "#cddc39",
            // accent: "#ffeb3b",
            error: {
                base: "#E71D1D",
                lighten5: '#FEF4F4',
            },
            warning: {
                base: "#FF7A2F",
                lighten5: '#FFEBE0',
            },
            info: {
                base: "#005EEA",
                lighten4: '#CCDFFB',
                lighten5: '#EBF2FD',
            },
            success: {
                base: "#07C07E",
                lighten5: '#E6F9F2',
            },
            text: {
                base: '#49495F',
                darken1: '#333342',
                darken2: '#21212B',
                lighten1: '#9494AD'
            },
            sand: {
                lighten5: '#FFF7E5',
                base: '#FFCD51',
                darken1: '#FFB600',
                darken2: '#DB9C00',
            },
        },
        dark: {
            primary: '#fff',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
    }
}

export default themeSettings