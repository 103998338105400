<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9839 3.01606C24.9625 -1.00538 18.4191 -1.00532 14.3977 3.01606C11.6511 5.76268 10.6973 9.81137 11.8861 13.4837L0.274688 25.0952C0.0988125 25.2711 0 25.5096 0 25.7582V31.0624C0 31.5802 0.419813 32 0.937625 32H6.24175C6.4905 32 6.72888 31.9012 6.90481 31.7253L8.23106 30.3991C8.43363 30.1964 8.5325 29.9124 8.49938 29.6278L8.33388 28.2038L10.3079 28.0177C10.7561 27.9755 11.1111 27.6206 11.1534 27.1723L11.3396 25.1981L12.7636 25.3636C13.0295 25.3946 13.2956 25.3104 13.4955 25.1324C13.6953 24.9546 13.8096 24.6997 13.8096 24.4322V22.6861H15.5239C15.7708 22.6861 16.0076 22.5887 16.1831 22.4152L18.5126 20.1126C22.1859 21.3033 26.2366 20.3497 28.984 17.6022C33.0053 13.5809 33.0053 7.03743 28.9839 3.01606ZM27.6579 16.2763C25.2913 18.6429 21.7413 19.3816 18.614 18.1584C18.2689 18.0235 17.8768 18.1044 17.6133 18.3648L15.1388 20.8108H12.8719C12.3541 20.8108 11.9343 21.2306 11.9343 21.7484V23.3792L10.6043 23.2247C10.3537 23.1956 10.1021 23.2686 9.906 23.4272C9.71 23.5859 9.58613 23.8169 9.56244 24.068L9.35913 26.2236L7.20375 26.4267C6.95263 26.4504 6.72169 26.5742 6.56294 26.7702C6.40425 26.9662 6.33119 27.218 6.36031 27.4685L6.58419 29.3937L5.85331 30.1248H1.87519V26.1467L13.6313 14.3907C13.8946 14.1273 13.9772 13.733 13.8415 13.3861C12.6183 10.2587 13.3571 6.70887 15.7236 4.34218C19.014 1.05193 24.3676 1.05193 27.6579 4.34218C30.9481 7.63237 30.9481 12.9861 27.6579 16.2763Z"
      fill="currentColor"
    />
    <path
      d="M26.3319 5.66813C25.2351 4.57132 23.4505 4.57138 22.3538 5.66813C21.2571 6.76488 21.2571 8.54944 22.3538 9.64619C23.4505 10.7429 25.2351 10.7431 26.3319 9.64619C27.4286 8.54944 27.4286 6.76488 26.3319 5.66813ZM25.0059 8.32019C24.6403 8.68575 24.0455 8.68582 23.6799 8.32019C23.3143 7.95463 23.3143 7.35982 23.6799 6.99419C24.0464 6.62769 24.6394 6.62769 25.0059 6.99419C25.3724 7.36069 25.3723 7.95369 25.0059 8.32019Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>