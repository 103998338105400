const AlertMixin = {
    props: {
        item: {
            typeof: Object,
            default: () => { },
        },
    },
    data: function() {
        return {
            timeout: ''
        }
    },
    methods: {
        removeAlert: function () {
            this.$emit("removeAlert", this.item.id);
        },
        alertTimeout: function () {
            this.timeout = setTimeout(() => { this.removeAlert() }, 5000)
        },
        resetTimeout: function() {
            clearTimeout(this.timeout);
        }
    },
    created: function () {
        this.alertTimeout()
    }
}

export default AlertMixin